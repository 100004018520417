.product{
  &_border{
    border: 1px solid  #ddd;
    border-radius: 0.625rem;
  }
  &_title{}
  &_header{
    margin-bottom: 2rem;
  }
  &_article{
    height: 1.5rem;
    padding: 0 0.375rem;
    background-color: $red_light;
    color: #fff;
    display: flex;
    align-items: center;
    width: max-content;
    font-size: $font-size-sm;
    margin-right: 1rem;
    border-radius: 0.1875rem;
  }
  .reviews{
    display: flex;
    align-items: center;
    font-size: $font-size-sm;
  }
  &_body{
    background-color: #f7f7f7;
    .gray{
      color: $gray-300;
    }
    &__left{
      .review_count{
        height: 1.5rem;
        padding: 0 0.5rem;
        color: #fff;
        font-weight: 600;
        font-size: $font-size-base;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $orange;
        border-radius: 0.3125rem;
      }
    }
    &__right{
      .size{
        span{
          color: #000;
          text-decoration: underline;
          font-weight: 500;
          cursor: pointer;
        }
        svg{
          fill: $blue;
          width: 1.0625rem;
          height: 1.0625rem;
        }
        &_blocks{
          display: grid;
          grid-template-columns: 3rem 3rem 3rem 3rem 3rem 3rem;
          gap: 0.3125rem;
          padding-bottom: 1.875rem;
          margin-bottom: 1.5rem;
          border-bottom: 1px solid #EDEDED;
          &>div, a{
            cursor: pointer;
            background-color: #F8F8FD;
            color: #000;
            border-radius: $border-radius;
            padding: 0.625rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 2.5rem;
            min-width: 3.06rem;
            max-height: 8rem;
            position: relative;
            border: 1px solid transparent;
            &.not-available{
              opacity: 0.3;
            }
            &.active{
              background-color: #303030;
              color: #fff;
            }
            &.sm_3{
              height: 2.8rem;
            }
            svg{
              opacity: 0;
              position: absolute;
              width: 4rem;
              height: 4rem;
              z-index: 1;
            }
            &.sending{
              opacity: 0.5;
              cursor: not-allowed;
              pointer-events: none;
              span{
                opacity: 0.5;
              }
              svg{
                opacity: 1;
              }
            }
            &.modification__button--stockout{
              opacity: 0.7;
              border: 1px solid transparent;
            }
            &.modification__button--active{
              border: 1px solid $blue;
            }
            span{
              font-size: 1.125rem;
              font-weight: 400;
              line-height: 1.25rem;
              //&+span{
              //  font-size: 0.75rem;
              //  line-height: 1.25rem;
              //}
              //&:last-child{
              //  font-size: 0.6875rem;
              //  line-height: 1.25rem;
              //}
            }
          }
        }
      }
      .bay_block{
        display: flex;
        margin: 0 -0.4375rem;
        padding-bottom: 1rem;
        &.last{
          border-bottom: 1px solid #EDEDED;
          padding-bottom: 1.25rem;
          margin-bottom: 1.25rem;
        }
        &_item{
          flex: 0 0 50%;
          max-width: 50%;
          padding: 0 0.4375rem;
          .available{
            font-size: 0.625rem;
            font-weight: 500;
            color: #000;
            svg{
              width: 0.75rem;
              height: 0.75rem;
              margin-right: 0.1875rem;
              fill: $green;
            }
          }
          .not-available{
            font-size: 0.625rem;
            font-weight: 500;
            color: $gray-200;
            svg{
              width: 0.75rem;
              height: 0.75rem;
              margin-right: 0.1875rem;
              fill: $gray-200;
            }
          }
        }
        &_count{
          background-color: #F9F9FF;
          border-radius: 0.25rem;
          padding: 0.5rem 0;
          height: 2.25rem;
          .count_symbol{
            font-size: 1rem;
            color: #000;
            font-weight: 400;
            padding: 0 0.25rem 0 0.75rem;
            cursor: pointer;
            &.left{
              padding: 0 0.75rem 0 0.25rem;
            }
          }
          input{
            width: 1.5rem;
            border: none;
            background-color: transparent;
            text-align: center;
          }
        }
      }
    }
  }
  &_container{
    margin: 0 2.625rem;
    &.small{
      margin: 0 -0.1875rem;
      display: flex;
      overflow: auto;
      padding-bottom: 1.25rem;
      .product_container__wrapper{
        padding: 0 0.1875rem;
      }
      .product_container__item{
        border: 1px solid #ddd;
        width: 8.75rem;
        &-img{
          width: 100%;
          height: 6.93rem;
        }
        &-info{
          padding: 0.625rem 0.75rem 0.25rem 0.75rem;
          .desc{
            margin-bottom: 0.5rem;
          }
        }
      }
      .product_container__footer{
        .sum{
          font-weight: 700;
          display: flex;
          align-items: flex-end;
          position: relative;
          .discount{
            padding: 0.125rem;
            color: #fff;
            background-color: $dark-orange;
            font-size: 0.5rem;
            line-height: 0.5625rem;
            font-weight: 500;
            left: 0;
            top: -0.125rem;
            position: absolute;
          }
        }
        .cart{
          width: 1.875rem;
          height: 1.75rem;
          svg{
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }
    &__wrapper{
      padding: 0 0.625rem;
      &.product_sm{
        padding: 0 0.1875rem 0.375rem 0.1875rem;
        .product_container{
          &__item{
            width: 100%;
            max-width: 13.875rem;
            box-shadow: 0 0 3.125rem rgba(0, 0, 0, 0.04);
            &-img{
              width: 100%;
              height: 6.9375rem;
            }
            &-info{
              padding: 0 0.75rem 0.75rem;
              .desc{
                font-weight: 400;
                font-size: $font-size-sm;
                line-height: 0.9rem;
                margin-bottom: 1.125rem;
              }
            }
          }
          &__footer{
            display: flex;
            justify-content: space-between;
            .sum{
              font-size: $font-size-md;
              font-weight: 500;
              line-height: 1rem;
            }
            .cart{
              background-color: $blue;
              width: 2.125rem;
              height: 2rem;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 0.25rem;
              svg{
                fill: $white;
                width: 1.125rem;
                height: 1.125rem;
              }
            }
          }
        }
      }
    }
    &__item{
      background-color: #fff;
      border-radius: 0.625rem;
      overflow: hidden;
      width: 100%;
      max-width: 13.875rem;
      position: relative;
      .product_status{
        padding: 0.25rem 0.75rem;
        height: 1.5rem;
        border-radius: 1.5rem;
        font-size: $font-size-md;
        font-weight: 500;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $gray;
        position: absolute;
        top: 1.125rem;
        left: 1.125rem;
        z-index: 10;
        &.action{
          background-color: $dark-orange;
        }
        &.sale{
          background-color: $green;
        }
      }
      &-img{
        width: 100%;
        height: 9.625rem;
        img{
          width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }
      &-info{
        padding: 0 0.75rem 0.75rem;
        .desc{
          font-weight: 400;
          font-size: $font-size-sm;
          line-height: 0.9rem;
          margin-bottom: 1.125rem;
        }
      }
    }
    &__footer{
      display: flex;
      justify-content: space-between;
      .sum{
        font-size: $font-size-md;
        font-weight: 500;
        line-height: 1rem;
      }
      .cart{
        background-color: $blue;
        width: 2.125rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.25rem;
        svg{
          fill: $white;
          width: 1.125rem;
          height: 1.125rem;
        }
      }
    }
  }
  &_size__grid{
    &-title{
      display: flex;
      height: 2.125rem;
      padding: 0 1.125rem;
    }
    &-line{
      display: flex;
      height: 2.125rem;
      padding: 0 1.125rem;
      &:nth-child(2n){
        background-color: $gray-100;
        border-radius: 0.25rem;
      }
    }
    .item{
      flex: 0 0 50%;
      max-width: 50%;
      display: flex;
      align-items: center;
      font-size: $font-size-md;
      &:first-child{
        font-weight: 600;
      }
    }
  }
  .gray_block{
    background-color: $gray-100;
    padding: 1.25rem;
    border-radius: 0.25rem;
  }
  .modification__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 0 1rem 0;
  }
  .modification__item {
    position: relative;
    margin: 0;
    display: block;
    border: 0;
    padding: 1px !important;
    height: 3rem;
  }
  .modification__item--active .modification__color, .modification__item--active .modification__preview {
    cursor: default;
  }
  .modification__color {
    display: block;
    position: absolute !important;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: unset !important;
    height: unset !important;
    box-shadow: unset !important;
  }
  .modification__color, .modification__preview {
    border-radius: $border-radius;
    position: relative;
    background-size: cover !important;
  }
  .modification__item--active .modification__color:before, .modification__item--active .modification__preview:before {
    content: '';
    position: absolute;
    border: 1px solid #0466b1;
    border-radius: $border-radius;
    width: calc(100% - 2px);
    top: 0;
    bottom: 0;
  }
}