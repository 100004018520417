.my_reviews {
  .reviews_block {
    padding: 1.25rem;
    &+.reviews_block{
      margin-top: 1.5rem;
    }
    &__img{
      padding: 0.5rem;
      background-color: $white;
      border-radius: $border-radius;
      width: 11.56rem;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      &-img{
        flex: 0 0 3.25rem;
        max-width: 3.25rem;
        height:  3.25rem;
        img{
          width: 100%;
          min-height: 100%;
          object-fit: cover;
        }
      }
      &-text{
        font-size: $font-size-sm;
        font-weight: 400;
      }
    }
    &__edit{
      span{
        &:last-child{display: none;}
      }
      &.editing{
        span{
          &:first-child{display: none;}
          &:last-child{display: block;}
        }
      }
    }
    .btn.btn_sm{
      height: 2rem;
      padding: 0 1.125rem;
      font-size: $font-size-md;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  .categories{
    flex-wrap: nowrap !important;
    padding-bottom: 0.5rem;
    &_item{
      flex: 0 0 13.875rem;
      max-width: 13.875rem;
      .category_card{
        height: unset !important;
        &__img{
          width: 9.625rem !important;
          height: 9.625rem !important;
        }
        &__title{
          text-align: left !important;
        }
      }
    }
  }
}