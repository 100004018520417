.header{
  border-bottom: 1px solid #EAEAEA;
  padding-bottom: 1rem;
  margin-bottom: 0.25rem;
  .search-input{
    position: absolute;
    width: 100%;
    left: 0;
    border-top: 1px solid #ccc;
    box-shadow: 0 0.25rem 0.625rem rgba(0,0,0,0.1);
    border-radius: 0 0 $border-radius*2 $border-radius*2;
    overflow: hidden;
    z-index: 2;
  }
  .search_result{
    &__item{
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      &:last-child{
        margin-bottom: 0;
      }
      &-img{
        flex: 0 0 3.125rem;
        max-width: 3.125rem;
        height: 3.125rem;
        border-radius: $border-radius;
        margin-right: 1rem;
        img{
          width: 100%;
          min-height: 100%;
          object-fit: contain;
        }
      }
      &-text{
        font-weight: 400;
        font-size: 0.875rem;

      }
    }
  }
  &_container{
    &.light{
      height: 3.3125rem;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      svg{
        left: 1.25rem;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  .burger{
    width: 4.2rem;
    height: 3rem;
    margin-left: -1rem;
    .ham {
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      transition: transform 400ms;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      width: 4.2rem;
      height: 3rem;
      &.active {
        width: 4.2rem;
        height: 4.2rem;
      }
      &Rotate{
        &.active {
          transform: rotate(45deg);
        }
        &180.active {
          transform: rotate(180deg);
        }
      }
      .line {
        fill:none;
        transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
        stroke:#000;
        stroke-width:2;
        stroke-linecap:round;
      }
      &4{
        .top {
          stroke-dasharray: 40 121;
        }
        .bottom {
          stroke-dasharray: 40 121;
        }
        &.active{
          .top {
            stroke-dashoffset: -68px;
          }
          .bottom {
            stroke-dashoffset: -68px;
            stroke-dasharray: 40 121;
          }
        }
      }
    }
    }
  .logo{
    width: 8.66rem;
    height: 2.3125rem;
  }
  .find{
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .cart{
    margin-left: 1.25rem;
    display: flex;
    align-items: center;
    &_count{
      width: 1rem;
      height: 1rem;
      border-radius:100%;
      background-color: $red;
      color: #fff;
      font-size: 0.75rem;
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 0.3rem;
      top: -0.05rem;
    }
    svg{
      width: 1.875rem;
      height: 1.875rem;
      margin-right: 0.75rem;
      fill: #0F8CFF;
    }
  }
  .menu{
    position: fixed;
    z-index: 10;
    width: 17.5rem;
    background-color: #fff;
    top: 0;
    height: 100vh;
    max-height: max-content;
    overflow: auto;
    transition: .3s;
    left: -100%;
    &.show, &.visible{
      left: 0;
    }
    &_header{
      display: flex;
      align-items: center;
      height: 3.375rem;
      padding: 0 1.25rem;
      background-color: $gray-100;
      svg{
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1.5rem;
      }
      .logo{
        width: 8.25rem;
        height: 2.1875rem;
        margin-right: 0;
      }
    }
    &_container{
      padding: 1.5rem;
      &__links{
        display: flex;
        flex-direction: column;
        padding: 1.25rem;
        &--item{
          color: $gray;
          padding: 0.625rem 0;
          &:first-child{
            padding-top: 0;
          }
          &:last-child{
            padding-bottom: 0;
          }
          a{
            color: $gray;
            padding: 0.625rem 0;
            &:first-child{
              padding-top: 0;
            }
            &:last-child{
              padding-bottom: 0;
            }
          }
        }
      }
    }
    .socials_langs{
      display: flex;
      flex-direction: column;
      padding: 1.25rem;
      &__icons{
        margin: 0 -0.5rem;
        a{
          padding: 0 0.5rem;
          svg{
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
    }
    .catalog{
      padding-top: 1rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      flex-direction: column;
      &_item{
        display: flex;
        align-items: center;
        height: 3rem;
        padding: 0 0.75rem;
        background-color: $gray-100;
        cursor: pointer;
        &:nth-child(2n){
          background-color: $white;
        }
        img{
          width: 1.5rem;
          height: 1.5rem;
          margin-right: 0.625rem;
        }
        span{
          font-size: $font-size-md;
          color: #000;
          line-height: 1rem;
          font-weight: 500;
        }
      }
    }
  }
}
