.breadcrumbs{
  padding: 1rem 0;
  font-size: $font-size-sm;
  a{
    padding-left: 0.625rem;
    position: relative;
    svg{
      width: 0.25rem;
      height: 0.375rem;
      stroke: $blue;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }
  }
  &>span{
    color: $gray-light;
  }
}