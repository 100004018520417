.before_footer{
  padding-top: 4.375rem;
  display: flex;
  flex-direction: column;
  &__item{
    background-color: $white;
    padding: 1.25rem 1.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #dddddd;
    &:last-child{
      border: none;
    }
    &-img{
      padding-right: 1.125rem;
      svg{
        width: 3.3125rem;
        height: 3.3125rem;
      }
    }
    &-desc{
      .title{
        font-size: 1rem;
        font-weight: 500;
        padding-bottom: 0.25rem;
      }
      .text{
        font-size: 0.75rem;
        font-weight: 400;
      }
    }
  }
}
.before_footer-lading{
  .before_footer{
    padding-top: 0;
    &__item {
      background-color: $gray-500;
      min-height: 6.875rem;
    }
  }
}

.brands{
  &_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0.5rem 1rem 1.2rem;
    background-color: #fff;
  }
  &_item{
   flex: 0 0 5rem;
    //height: 6.25rem;
    padding: 0 0.5rem;
    &:last-child{
      flex: 0 0 10rem;
      padding-right: 0;
      flex-grow: 1;
    }
    img{
      width: 100%;
    }
  }
}