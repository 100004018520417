.article_page{
  &__content{
    padding: 0 0.75rem;
    p{
      font-size: 1rem;
    }
  }
  .articles{
    padding-top: 2.8rem;
    &_slider__item{
      padding: 0 0.4375rem;
      width: 13.5rem;
      margin-bottom: 1.25rem;
    }
  }
}
.prod_theme{
  .product_container__item{
    max-width: unset;
    width: 14.125rem;
    margin-bottom: 1rem;
  }
}
