.filter{
  svg{
    width: 0.625rem;
    height: 1rem;
    margin-right: 0.625rem;
  }
}
.my_orders, .showed{
  .search-tab-nav {
    justify-content: flex-start;
    ul {
      display: flex;

      li a{
        margin-right: 0.25rem;
        box-shadow:unset;
        &.active-link{
          background-color: #fff;
          color: #333;
          border: 1px solid $blue;
        }
      }
    }
  }
}

