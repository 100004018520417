.mm-blocker{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 0;
  opacity: 0;
  transition: 0.3s;
  &.show{
    opacity: 1;
  }
}
._filter{
  position: relative;

}
.filter{
  padding: 1rem 1.25rem;
  &-item{
    padding: 0.75rem;
    border-radius: $border-radius;
    &:nth-child(2n){
      background-color: $gray-500;
    }
    &+.filter-item{
      margin-top: 0.5rem;
    }
    &__title{
      display: block;
      font-size: $font-size-md;
      font-weight: 500;
      &+*{
        margin-top: 0.75rem;
      }
    }
    &__link{
      display: flex;
      .filter-item__checkbox{
        display: block;
        border: 1px solid #DDDDDD;
        border-radius: 0.1875rem;
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.625rem;
        position: relative;
      }
    }
    input[type=checkbox]{
      position: absolute;
      opacity: 0;
      pointer-events: none;
      &:checked+label .filter-item__checkbox{
        background-color: $blue;
        border-color: $blue;
        i{
          position: absolute;
          left: 50%;
          top: 42%;
          transform: translate(-50%,-50%) rotateZ(-45deg);
          width: 0.75rem;
          height: 0.35rem;
          border-left: 2px solid #fff;
          border-bottom: 2px solid #fff;
        }
      }
    }
    .range-select{
      &__inputs{
        display: flex;
        margin: 0 -0.25rem;
      }
      &__input{
        padding: 0 0.25rem;
        .input{
          border: 1px solid $gray-400;
          border-radius: 0.25rem;
          width: 5rem;
          height: 2rem;
        }
        .btn{
          border: none;
          font-size: .875rem;
          color: #fff;
          width: 2.5rem;
          height: 2rem;
          border-radius: 0.1875rem;
          background-color: #0F8CFF;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          margin-left: 0.25rem;
        }
      }
      &__separator{
        display: flex;
        align-items: center;
        justify-content: center;
        &::before{
          content: '-';
        }
      }
    }
  }
}
.catalog-settings{
  top: 0;
  position: fixed;
  width:18.2rem;
  background-color: $white;
  height: 100%;
  z-index: 1;
  left: -100%;
  transition: .3s;
  &.mm-opened{
    left: 0;
  }
  &__block{
    position: static !important;
    width: unset;
  }
  .mm-panel{
    &+.mm-panel{
      height: 100%;
      .mm-subnav{
        height: 3.375rem;
        background-color: $gray-100;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .mm-btn{
          &::before{
            content: '';
            width: 0.625rem;
            height: 0.625rem;
            border-left: 1px solid $black;
            border-top: 1px solid $black;
            transform: rotateZ(-45deg) translateY(-50%);
            position: absolute;
            left: 1.75rem;
            top: 50%;
          }
        }
        .mm-title{
          font-weight: 600;
          color: $black;
        }
      }
    }
    &:last-child{
      padding-bottom: 0;
    }
  }
  .btn-toolbar{
    display: flex;
    padding: 1rem 1.25rem;
    button+button{
      margin-left: 0.625rem;
    }
  }
  &__header{
    height: 3.375rem;
    background-color: $gray-100;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  &__title{
    font-weight: 600;
    color: $black;
  }
  &__close{
    position: absolute;
    left: 1.25rem;
    top: 50%;
    transform: translateY(-50%);
    button{
      background-color: transparent;
      border: none;
      padding: 0;
    }
    .icon--menu-hide{
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  &__content{
    .heading{display: none;}
  }
}
.mm-hasnavbar{
  top: 0;
  position: fixed;
  width: 18.2rem;
  background-color: #fff;
  //height: 100%;
  z-index: 2;
  transition: .3s;
  left: -100%;
  &.mm-opened{
    left: 0;
  }
  .mm-subnav{

  }
}

.get_filters{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2.1875rem;
  &__item{
    height: 1.25rem;
    background-color: $blue;
    margin-right: 0.625rem;
    margin-bottom: 0.625rem;
    padding: 0 0.375rem;
    color: $white;
    font-size: $font-size-md;
    display: flex;
    align-items: center;
    border-radius: 0.25rem;
    white-space: nowrap;
    svg{
      width: 0.75rem;
      height: 0.75rem;
      margin-left: 0.625rem;
    }
    &:last-child{
      margin: 0;
    }
  }
}
.gray_block{
  padding: 1rem 1.25rem;
  max-width: 16.8rem;
  margin-right: 1rem;
  margin-bottom: 1.5rem;
  &:last-child{
    margin-right: 0;
  }
  .reviews_block__img{
    width: 11.56rem;
    height: 4.06rem;
    background-color: #fff;
    border-radius: $border-radius;
    margin: 0.625rem 0 0.625rem;
    &-text{
      padding: 0.3125rem 0.625rem 0.75rem 0.875rem;
      font-size: $font-size-sm;
    }
    &-img{
      width: 3.25rem;
      height: 3.25rem;
      border-radius: $border-radius;
      img{
        object-fit: contain;
        max-height: 100%;
      }
    }
  }
}
.filter{
  &-current{
    &-group{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      &+.filter-current-group{
      }
    }
    &-heading{
      padding-right: 0.5rem;
      margin-bottom: 0.3rem;
      i{display: none;}
    }
    &-i{
      padding: 0.125rem 0.375rem;
      border-radius: 0.25rem;
      background-color: $blue;
      color: $white;
      display: flex;
      align-items: center;
      width: max-content;
      margin-right: 0.625rem;
      margin-bottom: 0.5rem;
    }
  }
  &-clear{
    height: 2rem;
    padding: 0 1.25rem;
    display: flex;
    align-items: center;
    background-color: $gray-400;
    border-radius: 0.25rem;
    width: max-content;
    margin-top: 0.75rem;
  }
  &-section{
    &-h{
      margin-bottom: 1.25rem;
    }
  }
  &-lv1{
    margin-top: 0;
  }
}
.article{
  &-title{
    font-weight: 500;
    margin-bottom: 1.25rem;
  }
  &-list{
    font-size: $font-size-md;
    color: $blue;
    margin-bottom: 1rem;
    display: block;
  }
}
.bottom-link{
  padding: 2.8rem 0;
  a{
    color: $black;
    text-decoration: none;
    border-bottom: 1px dotted $black;
    margin-bottom: 0.75rem;
  }
}
.result{

  &_product{
    &__wrapper{
      display: flex;
      flex-wrap: wrap;
    }
    width: 10rem;
    padding: 0 0.1875rem;
    margin-bottom: 0.375rem;
    .product_container__item{
      max-width: unset;
      .available{
        font-size: $font-size-sm;
        align-items: center;
      }
      &-img{
        height: 6.9375rem;
      }
      &-info{
        .desc{
          font-size: $font-size-md;
        }
      }
    }
  }
  &_catalog{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.375rem;
    align-items: center;
    .catalog-grid__item{
      flex: 0 0 16%;
      max-width: 16%;
      padding: 0 0.375rem;
      margin-bottom: 0.75rem;
      .catalogCard{
        background-color: #fff;
        box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.04);
        border-radius: $border-radius;
        padding: 0.625rem;
        &-a{
          display: flex;
          flex-direction: column;
        }
        &-image{
          margin-bottom: 0.5rem;
          img,picture{
            width: 100%;
            height: 7.5rem;
            object-fit: contain;
          }
        }
        &-title{
          font-size: $font-size-md;
          font-weight: 400;
          text-align: center;
        }
      }
    }
  }
  .result_sort{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3.375rem 0 1.625rem;
    &__items{

    }
    &__block{
      display: flex;
      align-items: center;
      &::-webkit-scrollbar-thumb{background: transparent !important;}
      &::-webkit-scrollbar-track{background: transparent !important;}
      &-item{
        padding: 0 0.3125rem;
        &:first-child{
          padding-left: 0;
        }
        &>*{
          white-space: nowrap;
        }
        .white-box{
          padding: 0 1rem;
          height: 2.625rem;
          display: flex;
          align-items: center;
          font-size: $font-size-md;
          color: #000;
          font-weight: 400;
          background-color: #fff;
          border-radius: $border-radius;
          img{
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0.625rem;
          }
        }
      }
    }
  }
}