html, body {
  font-size: 4.44vw;
  //font-size: 16px;
}
body{
  font-family: $basefont;
  margin: 0;
  padding: 0;
  background-color: #F8F8FE;
  counter-reset: section;
  *{
    box-sizing: border-box;
  }
  ul{
    padding-left: unset;
    li{
      list-style: none;
    }
  }
  h1,h2,h3,h4{
    margin: 0;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .show{
    display: block !important;
  }
  .row{
    margin-right: -0.625rem;
    margin-left: -0.625rem;
    display: flex;
    flex-wrap: wrap;
  }
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto{
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  @media(min-width: 768px){
    .col-md-6{
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  .col-6{
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-4{
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .col-8{
    flex: 0 0 66.66%;
    max-width: 66.66%;
  }
  .offset-md-4{
    margin-left: 33.33%;
  }
  .backdrop{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background-color: rgba(0,0,0,0);
    pointer-events: none;
    transition: .3s;
  }
  &.modal_show{
    //overflow: hidden;
    .backdrop{
      pointer-events: unset;
      background-color: rgba(0,0,0,0.5);
    }
  }
  .overflow-auto{
    overflow: auto;
  }
  textarea{
    font-family: $basefont;
    font-size: 0.875rem;
    padding: 1rem 1rem 0 1rem;
    margin: 1rem 0;
    background-color: transparent;
    border-radius: $border-radius;
    &.disabled{
      padding-left: 0;
      padding-right: 0;
      border-color: transparent;
      pointer-events: none;
      margin: 0;
    }
  }
}
a{
  color: #333;
  text-decoration: none;
  &:hover{
    color: #000;
  }
  &.blue{
    color: $blue;
    &:hover{
      color: #0F74E4;
    }
  }
}
.container{
  width: 100%;
}
.container_fluid{
  width: 100%;
  padding: 0 1.25rem;
}
.header_container{
  padding: 0 1.25rem;
  width: 100%;
}
.overflow-hidden{overflow: hidden;}

.brdr-0{border-radius: 0 !important;}

.d-flex{display: flex !important;}
.flex-column{flex-direction: column !important;}
.flex-row{flex-direction: row !important;}
.justify-content-around{justify-content: space-around !important;}
.justify-content-between{justify-content: space-between !important;}
.justify-content-center{justify-content: center !important;}
.justify-content-end{justify-content: flex-end !important;}
.justify-content-start{justify-content: flex-start !important;}
.align-items-center{align-items: center !important;}
.align-items-start{align-items: flex-start !important;}
.align-items-end{align-items: flex-end !important;}
.flex-wrap{flex-wrap: wrap !important;}

.position-relative{position: relative !important;}
.position-absolute{position: absolute !important;}

.w-100{width: 100% !important;}
.pt-0{padding-top: 0 !important;}
.pb-0{padding-bottom: 0 !important;}
.pl-0{padding-left: 0 !important;}
.pr-0{padding-right: 0 !important;}
.mt-0{margin-top: 0 !important;}
.mb-0{margin-bottom: 0 !important;}
.mr-0{margin-right: 0 !important;}
.ml-0{margin-left: 0 !important;}
.pt-1{padding-top: 0.25rem !important;}
.mt-1{margin-top: 0.25rem !important;}
.pt-2{padding-top: 0.5rem !important;}
.mt-2{margin-top: 0.5rem !important;}
.pt-3{padding-top: 1rem !important;}
.mt-3{margin-top: 1rem !important;}
.pt-4{padding-top: 2rem !important;}
.mt-4{margin-top: 2rem !important;}
.pt-5{padding-top: 3rem !important;}
.mt-5{margin-top: 3rem !important;}
.pb-1{padding-bottom: 0.25rem !important;}
.mb-1{margin-bottom: 0.25rem !important;}
.pb-2{padding-bottom: 0.5rem !important;}
.mb-2{margin-bottom: 0.5rem !important;}
.pb-3{padding-bottom: 1rem !important;}
.mb-3{margin-bottom: 1rem !important;}
.pb-4{padding-bottom: 2rem !important;}
.mb-4{margin-bottom: 2rem !important;}
.pb-5{padding-bottom: 3rem !important;}
.mb-5{margin-bottom: 3rem !important;}
.pl-1{padding-left: 0.25rem !important;}
.ml-1{margin-left: 0.25rem !important;}
.pl-2{padding-left: 0.5rem !important;}
.ml-2{margin-left: 0.5rem !important;}
.pl-3{padding-left: 1rem !important;}
.ml-3{margin-left: 1rem !important;}
.pl-4{padding-left: 2rem !important;}
.ml-4{margin-left: 2rem !important;}
.pl-5{padding-left: 3rem !important;}
.ml-5{margin-left: 3rem !important;}
.pr-1{padding-right: 0.25rem !important;}
.mr-1{margin-right: 0.25rem !important;}
.pr-2{padding-right: 0.5rem !important;}
.mr-2{margin-right: 0.5rem !important;}
.pr-3{padding-right: 1rem !important;}
.mr-3{margin-right: 1rem !important;}
.pr-4{padding-right: 2rem !important;}
.mr-4{margin-right: 2rem !important;}
.pr-5{padding-right: 3rem !important;}
.mr-5{margin-right: 3rem !important;}
.m-auto{margin: auto !important;}
.p-0{padding: 0 !important;}
.m-0{margin: 0 !important;}

.w-100{width: 100%;}

.flex-grow-1{flex-grow: 1 !important;}

.font_400{font-weight: 400 !important;}
.font_500{font-weight: 500 !important;}
.font_600{font-weight: 600 !important;}
.font_700{font-weight: 700 !important;}

.text-base{font-size: $font-size-base !important;}
.text-xl{font-size: $font-size-xl !important;}
.text-lg{font-size: $font-size-lg !important;}
.text-md{font-size: $font-size-md !important;}
.text-sm{font-size: $font-size-sm !important;}
.text-xs{font-size: $font-size-xs !important;}

.text-center{text-align: center;}

.text-white{color: #fff !important;}
.text-blue{color: $blue !important;}
.text-danger{color: $red !important;}
.text-violet{color: $violet !important;}
.text-gray{color: $gray-light !important;}
.text-gray200{color: $gray-200 !important;}
.text-black{color: $black !important;}
.text-black-light{color: $black-light !important;}
.text-green{color: $green !important;}

.lh-1{line-height: 1rem !important;}
.lh-2{line-height: 1.125rem !important;}
.lh-3{line-height: 1.25rem !important;}
.lh-4{line-height: 1.5rem !important;}

.h1{
  font-size: $h1;
  font-weight: 400;
  line-height: 1.625rem;
}
//.h2{
//  font-size: $h2;
//  font-weight: 400;
//  line-height: 1.875rem;
//}
//.h3{
//  font-size: $h3;
//  font-weight: 400;
//  line-height: 1.22rem;
//}

.text-through{
  text-decoration: line-through;
}

.text-underline{
  text-decoration: underline;
}

.btn{
  height: 3.125rem;
  padding: 0 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  &-primary, &--primary{
    background-color: $blue;
    font-size: $font-size-lg;
    color: #fff !important;
    border: none;
    svg{
      width: 1.5rem;
      height: 1.5rem;
      fill: #fff;
      margin-right: 0.5rem;
    }
  }
  &-secondary, &--icon-left{
    background-color: $gray-100;
    color: #000;
    height: 2.5rem;
    svg{
      width: 1.5rem;
      height: 1.5rem;
      fill: $blue;
      margin-right: 0.5rem;
    }
  }
  &--primary, &--icon-left{
    height: 2.625rem;
    border: none;
    flex-grow: 1;
  }
  &_gray{
    background-color: $gray-100 ;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    font-size: $font-size-md;
    font-weight: 500;
    padding: 0 1.25rem;
    &.lg{
      height: 3.125rem;
      padding: 0 2rem;
      font-weight: 600;
    }
    &.md{
      height: 1.625rem;
      padding: 0 0.5rem;
      font-weight: 500;
      //font-size: $font-size-md;
    }
    &.sm{
      //height: 2rem;
      //padding: 0 0.75rem;
      //font-weight: 500;
      //font-size: $font-size-md;
    }
    &:hover{
      opacity: 0.95;
    }
    svg{
      width: 1.56rem;
      height: 1.56rem;
      fill: $blue;
    }
  }
  &-white{
    background-color: #fff;
    color: #000;
    padding: 0 1.25rem;
    height: 2.375rem;
    font-size: $font-size-md;
    width: max-content;
  }
  &.btn-primary-outline{
    height: 3.125rem;
    font-size: 1rem;
    line-height: 2.375rem;
    border: 1px solid $blue;
    color: $blue;
    background-color: transparent;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    width: max-content;
    &:hover{
      background-color: #fff;
    }
    svg{
      width: 1.375rem;
      height: 1.375rem;
      fill: $blue;
    }
  }
  &.btn-lg{
    height: 3.94rem;
  }
  &.btn_md{
    height: 2.875rem;
    width: max-content;
  }
  &.btn_sm{
    padding: 0 1.25rem;
    height: 2.25rem;
    width: max-content;
  }
  &.btn_xs{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem;
    height: 1.375rem;
  }
  &_sities{
    margin: 0 -0.25rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    &__item{
      padding: 0 0.25rem;
      margin-bottom: 0.5rem;
      flex-grow: 1;
      a{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 1.75rem;
        padding: 0 0.75rem;
        border-radius: $border-radius;
        background-color: $gray-100;
        border: 1px solid transparent;
        font-size: $font-size-md;
        color: #000000;
        &.active{
          border: 1px solid #0F8CFF;
        }
      }
    }
  }
}
.star_rating{
  display: flex;
  align-items: center;
  .stars_wrapper{
    padding: 0 0.5rem;
    &__active{
      overflow: hidden;
      top: 0;
    }
    svg{
      fill:$gray-light;
      width: 1rem;
      height: 1rem;
      &.set_rating{
        pointer-events: none;
        &:first-child{
          pointer-events: unset;
        }
        use{
          &:first-child{display: none;}
          &:last-child{display: block;}
        }
        &.check{
          &+.set_rating{
            pointer-events: unset;
          }
          use{
            &:first-child{display: block;}
            &:last-child{display: none;}
          }
        }
      }

    }
  }
  &.sm{
    svg {
      width: 0.9375rem;
      height: 0.875rem;
    }
  }
  &.xs{
    svg {
      width: 0.625rem;
      height:0.625rem;
    }
  }
}

.note{
  padding: 0.5rem 0.875rem;
  border-radius: 0.25rem;
  font-size: $font-size-md;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &+.note{
    margin-top: 1rem;
  }
  svg{
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.625rem;
  }
  &-orange{
    background-color: $dark-orange;
    color: #fff;
  }
  &-green{
    background-color: $green_ligh;
    color: $green;
    font-size: $font-size-sm;
    svg{
      fill: $green;
    }
  }
  &-gray{
    background-color: $gray-100;
    color: $gray-lighten;
    font-size: $font-size-sm;
    svg{
      fill: $blue;
    }
  }
}
.banner{
  padding: 1.25rem;
  background-size: cover;
  border-radius: 0.625rem;
  overflow: hidden;
  background-image: url(../img/banner_orange.jpg);
  &-wrapper{
    padding: 1.5rem 0;;
  }
  &.blue{
    background-image: url(../img/banner_blue.jpg);
  }
  &.banner_lg{
    background-image: url(../img/banner_lg.jpg);
    background-size: cover;
    border-radius: 0.625rem;
    .text{
      width: 70%;
    }
  }
  .text{
    width: 63%;
  }
}
.accordion{
  background-color: #F9F9FF;
  border-radius: 0.3125rem;
  &:nth-child(2n){
    background-color: #fff !important;
  }
  &_item {
    color: #000;
    cursor: pointer;
    padding: 1.5rem 1.25rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    transition: 0.4s;
    &__icon{
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.875rem;
    }
    &__title{
      font-weight: 500;
    }
    &__marker{
      flex: 0 0 1.125rem;
      max-width: 1.125rem;
      height: 1.125rem;
      margin-left: 1rem;
      &.plus{display: block;}
      &.minus{display: none;}
    }
    &.active{
      .accordion_item__marker{
        &.plus{display: none;}
        &.minus{display: block;}
      }
    }
  }
}
.panel {
  padding: 0 1.75rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  &_text{
    position: relative;
    margin-bottom: 1rem;
    &::after{
      content: '';
      position: absolute;
      left: 0;
      top: 0.375rem;
      border-radius: 100%;
    }
    &._black{
      padding-left: 1.5rem;
      &::after{
        width: 0.25rem;
        height: 0.25rem;
        background-color: #000;
      }
    }
    &._green{
      padding-left: 1.5rem;
      &::after{
        width: 0.5rem;
        height: 0.5rem;
        background-color: $green;
      }
    }
    &._red{
      padding-left: 1.5rem;
      &::after{
        width: 0.5rem;
        height: 0.5rem;
        background-color: $red;
      }
    }
  }
}
.info{
  &_item{
    padding: 1.5rem 1.375rem;
    background-color: $gray-100;
    border-radius: 0.3125rem;
    &__title{
      display: flex;
      align-items: center;
      font-size: $font-size-lg;
      font-weight: 500;
      svg{
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;
      }
    }
    &:nth-child(2n){
      background-color: #fff;
    }
  }
  &_sm{
    .info_item{
      padding: 1rem 1.25rem;
      background-color: $gray-100;
      border-radius: 0.3125rem;
      font-size: 1rem;
      align-items: flex-start;
      &.hide{
        display: none;
      }
      &>div>div{
        &:last-child{
          max-width: 15.31rem;
          text-align: right;
          font-weight: 500;
        }
      }
      .dashed{
        border-bottom: 1px dashed #9A9A9A;
        flex-grow: 1;
        margin: 0 0.5rem;
        height: 1.125rem;
      }
      &:nth-child(2n){
        background-color: #fff;
      }
    }
  }
  &_show__more{
    svg{
      width: 1.6rem;
      height: 1.6rem;
      transition: .3s;
    }
    &.more{
      svg{
        transform: rotateZ(180deg);
        transition: .3s;
      }
    }
  }
}
.card{
  padding: 1rem 1.25rem;
  background-color: #fff;
  border-radius: $border-radius*2;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.04);
  h4{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $font-size-lg;
      flex:0 0 2rem;
      max-width: 2rem;
      height: 2rem;
      border-radius: 100%;
      background-color: #F0F0FE;
      margin-right: 0.75rem;
    }
  }
  &+.card{
    margin-top: 0.625rem;
  }
  &_body{
    svg{
      width: 1.5rem;
      height: 1.25rem;
      &.icon-hint{
        fill: $gray-light;
      }
    }
  }
}


.person{
  background-color: $gray-100;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  padding: 0.75rem 0.875rem;
  max-width: 25rem;
  &_img{
    flex:0 0 2.875rem;
    max-width: 2.875rem;
    height: 2.875rem;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 0.5rem;
    img{
      width: 100%;
      min-height: 100%;
      object-fit: cover;
    }
  }
  &_text{
    color: #000;
    font-size: $font-size-md;
    font-weight: 400;
  }
}

.share{
  display: flex;
  align-items: center;
  svg{
    flex: 0 0 1.25rem;
    max-width: 1.25rem;
    height: 0.875rem;
    fill: $blue;
    margin-right: 0.25rem;
  }
  .title{
    font-size: $font-size-md;
  }
  &_icons{
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0 0 3.125rem rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    padding: 0.75rem;
    width: 9.375rem;
    top: 100%;
    right: 0;
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    transform: translateY(1rem);
    transition: .3s;
    &.visible{
      opacity: 1;
      pointer-events: unset;
      transform: translateY(0);
    }
    a{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 0.625rem;
      font-weight: 400;
      padding: 0.5rem 0;
      &:first-child{
        padding-top: 0;
      }
      &:last-child{
        padding-bottom: 0;
      }
      svg{
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
      }
    }
  }
}

.reviews_slider{
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: auto;
  &::-webkit-scrollbar {
    height: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: #A6A6A6;
    border-radius: 0.25rem;
  }
  &::-webkit-scrollbar-track {
    background: #F0F0F0;
  }
  .gray_block{
    flex: 0 0 16.81rem;
    max-width: 16.81rem;
  }
  &__item{
    height: 11rem;
    overflow: hidden;
    &.show{
      height: unset !important;
    }
    &-head{
      padding-bottom: 0.875rem;
      display: flex;
      justify-content: space-between;
    }
    .review_date{
      color: $gray-light;
      font-size: $font-size-xs;
    }
    .review_title{
      color: $black;
      font-size: $font-size-md;
      font-weight: 500;
    }
  }
}

.modal{
  position: fixed;
  opacity: 1;
  transition: .3s;
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 90%;
  max-width: 23.75rem;
  margin: 0 auto;
  max-height: 100%;
  .modal-dialog{
    width: 100%;
    background-color: #fff;
    padding: 2rem;
    border-radius: 0.625rem;
    margin-top: 0;
    transition: .3s;
    position: relative;
    .close{
      position: absolute;
      top: 1.125rem;
      right: 1.25rem;
      width: 0.9375rem;
      height: 0.9375rem;
      cursor: pointer;
    }
    .big_title{
      font-size: 1.625rem;
      font-weight: 400;
      line-height: 1.25rem;
    }
  }
  &.fade{
    opacity: 0;
    transition: .3s;
    pointer-events: none;
    .modal-dialog{
      margin-top: 1.5rem;

    }
  }
  &-content{
    padding-top: 1.5rem;
    overflow: auto;
    height: 100%;
  }
  &-footer{
    padding-top: 1.5rem;
  }
}
.widget{
  padding-top: 2rem;
  background-color: #f7f7f7;
  &_title{
    padding-bottom: 1.25rem;
    font-size: 1.375rem;
    font-weight: 00;
  }
}
.product{
  &_item{
    background-color: #fff;
    border-radius: 0.625rem;
    overflow: hidden;
    flex:0 0 9.8125rem;
    max-width: 9.8125rem;
    margin-right: 0.375rem;
    &-img{
      width: 100%;
      height: 6.94rem;
      img{
        width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
    &-info{
      padding: 0 0.75rem 1rem 0.75rem;
      .desc{
        font-weight: 400;
        font-size: $font-size-sm;
        line-height: 0.9rem;
        margin-bottom: 1rem;
      }
    }
  }
  &_footer{
    display: flex;
    justify-content: space-between;
    .sum{
      font-size: $font-size-md;
      font-weight: 500;
      line-height: 1rem;
    }
    .cart{
      background-color: $blue;
      width: 1.875rem;
      height: 1.75rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.25rem;
      svg{
        fill: $white;
        width: 1.125rem;
        height: 1.125rem;
      }
    }
  }
}

.scroll{
  &_horisontal{
    overflow-x: auto;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      height: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #A6A6A6;
      border-radius: $border-radius;
    }
    &::-webkit-scrollbar-track {
      background: #F0F0F0;
    }
  }
  &_vertical{
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #A6A6A6;
      border-radius: $border-radius;
    }
    &::-webkit-scrollbar-track {
      background: #F0F0F0;
    }
  }
}

.horisontal_scroll{
  //width: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    height: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: #A6A6A6;
    border-radius: 0.25rem;
  }
  &::-webkit-scrollbar-track {
    background: #F0F0F0;
  }
}
.input-group{
  position: relative;
  margin-bottom: 0.625rem;
  textarea{
    padding-top: 0.5rem;
    height: 7.5rem !important;
  }
  .form-control{
    background-color: #F9F9FF;
    border: 1px solid #F1EFEF;
    height: 2.375rem;
    padding: 0 2.5rem 0 1rem;
    color: #000;
    border-radius: 0.5rem;
    outline: none;
    &:focus-visible, &:focus, &:active{
      border-color: $blue;
    }
    &::placeholder {
      color: $gray-light;
    }
  }
  &-append{
    position: absolute;
    top: 0.625rem;
    right: 1.25rem;
    svg{
      width: 1.125rem;
      height: 1.125rem;
    }
  }
}

.sity{
  margin: 0 -0.25rem;
  display: flex;
  &-item{
    padding: 0 0.25rem;
    flex-grow: 1;
  }
}

.shop{
  margin: 0 -0.3125rem;
  display: flex;
  flex-wrap: wrap;
  &-item{
    padding: 0 0.3125rem 0.625rem;
    width: 50%;
  }
}
.gray_block{
  background: $gray-100;
  border-radius: 0.3125rem;
  padding: 1.5rem 2rem;
  &__md{
    padding:1.25rem;
  }
  &__sm{
    padding: 0.75rem 0.8125rem;
    color: #000;
    font-size: $font-size-md;
  }
}
.phone{
  padding:0 1.125rem;
  &>a{
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;
    svg{
      width: 1rem;
      height: 1rem;
      margin-right: 0.25rem;
    }
  }
}
.js-dropdown{
  position: relative;
  min-width: 2.5rem;
}
.wrapper_dropdown{
  top:100%;
  position: absolute;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: -2px 5px 15px -8px rgba(0, 0, 0, 0.56);
  width: 100%;
  pointer-events: none;
  opacity: 0;
  transform: translateY(0.5rem);
  transition: .3s;
  z-index: 2;
  &.visible{
    pointer-events: unset;
    opacity: 1;
    transition: .3s;
    transform: translateY(0);
  }
  &__item{
    font-size: $font-size-sm;
    color: $black;
    border-radius: 0.25rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    &:hover{
      color: $white;
      background-color: lighten($blue,15%);
    }
  }
}
.tiangle{
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.3125rem 0.156rem 0 0.156rem;
  border-color: #fff transparent transparent transparent;
  margin-left: 0.34rem;
  &.black{
    border-color: #000 transparent transparent;
  }
}
.mask{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.5);
  opacity: 0;
  pointer-events: none;
  transition: .3s;
  &.show{
    opacity: 1;
    pointer-events: unset;
  }
}
.sum{
  font-weight: 500;
  font-size: 1.75rem;
  .sum_text{
    font-size: 1.25rem;
  }
  .old_price{
    font-size: $font-size-sm;
    font-weight: 400;
    line-height: 1rem;
    color: $gray-300;
    text-decoration: line-through;
  }
  .discount{
    padding: 0.125rem;
    color: #fff;
    background-color: $dark-orange;
    font-size: 0.5rem;
    line-height: 0.5625rem;
    font-weight: 500;
    border-radius: 0.125rem;
  }
}
.bay_block{
  display: flex;
  margin: 0 -0.4375rem;
  padding-bottom: 1rem;
  &.last{
    border-bottom: 1px solid #EDEDED;
    padding-bottom: 1.25rem;
    margin-bottom: 1.25rem;
  }
  &_item{
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 0.4375rem;
  }
  &_count{
    background-color: #F9F9FF;
    border-radius: 0.25rem;
    padding: 0.5rem 0;
    height: 2.25rem;
    .count_symbol{
      font-size: 1rem;
      color: #000;
      font-weight: 400;
      padding: 0 0.25rem 0 0.75rem;
      cursor: pointer;
      &.left{
        padding: 0 0.75rem 0 0.25rem;
      }
    }
    input{
      width: 1.5rem;
      border: none;
      background-color: transparent;
      text-align: center;
    }
  }
}
.available{
  font-size: 0.625rem;
  font-weight: 500;
  color: #000;
  svg{
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.1875rem;
    fill: $green;
  }
}
.not-available{
  font-size: 0.625rem;
  font-weight: 500;
  color: $gray-200;
  svg{
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.1875rem;
    fill: $gray-200;
  }
}
.alert{
  border-radius: 5px;
  padding: 0.625rem 0.75rem 0.625rem 2.5rem;
  position: relative;
  margin-bottom: 0.75rem;
  &::before{
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
    top: 1rem;
    left: 1.125rem;
    position: absolute;
  }
  &-danger{
    background-color: $red_opacity;
    &::before{
      background-color: $red;
    }
  }
}

.triangle{
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.3125rem 0.1875rem 0 0.1875rem;
  border-color: #000 transparent transparent transparent;
  margin-left: 0.5rem;
  &.big{
    &.top{
      border-width: 0 0.25rem 0.4375rem 0.25rem;
      border-color: transparent transparent #000 transparent;
    }
    border-width: 0.4375rem 0.25rem 0 0.25rem;
    border-color: #000 transparent transparent transparent;
  }
}

.form-group{
  padding-bottom: 0.3125rem;
  position: relative;
  &+.form-group{
    padding-top: 1.5rem;
  }
  &.error{
    label{color: $red;}
    .form-control{border: 1px solid $red;}
  }
  label{
    display: block;
    font-weight: 400;
    color: $black;
    padding-bottom: 0.625rem;
  }
  .form-control{
    width: 100%;
    height: 3.375rem;
    padding: 0 5rem 0 1.125rem;
    border-radius: $border-radius;
    border: 1px solid #DDDDDD;
    background-color: #fff;
    resize: vertical;
    &:focus-visible{
      outline: none !important;
      border: 1px solid $blue;
    }
    &.sm{
      height: 2.875rem;
    }
    &.form_sm{
      height: 2.5rem;
    }
    &.error{
      border-color: $red;
    }
  }
  .error-text{
    position: absolute;
    bottom: -1rem;
    left: 0;
    font-size: $font-size-sm;
    color: $red;
  }
  select.form-control{
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right;
    -webkit-appearance: none;
    background-position: 97%;
    background-size: 0.75rem;
  }
  .input-group{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .form-error{
      color: $red;
      p{
        margin: 0.5rem 0;
      }
    }
    &-prepend{
      position: absolute;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      .btn{
        width: 3.875rem;
        height: 3.375rem;
        position: absolute;
        top: 0;
        border-radius: 0 $border-radius $border-radius 0;
        right: 0;
        background-color: $gray-400;
        font-size: 1rem;
        pointer-events: none;
      }
      .icon{
        width: 3.875rem;
        height: 3.375rem;
        position: absolute;
        top: 0;
        border-radius: 0 $border-radius $border-radius 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
          width: 1.25rem;
          height: 1rem;
          fill: $blue;
          &:first-child{display: none;}
          &:last-child{display: block;}
        }
        &.visible{
          svg{
            fill: $gray-400;
            &:first-child{display: block;}
            &:last-child{display: none;}
          }
        }
      }
    }
    .form-control{
      &.active + .input-group-prepend{
        .btn{
          background-color: $blue;
          pointer-events: unset;
        }
      }
    }
  }
  .text-sm{
    color: $gray-200;
  }
}

.checkbox{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  input{
    display: none;
  }
  .checker{
    flex:0 0 1.375rem;
    max-width: 1.375rem;
    height: 1.375rem;
    border-radius: $border-radius;
    border: 1px solid $gray-400;
    margin-right: 0.75rem;
    position: relative;
    &::before{
      content: "";
      position: absolute;
      left: 50%;
      top: 44%;
      transform: translate(-50%,-50%) rotateZ(-45deg);
      width: 0.75rem;
      height: 0.325rem;
      border-left:2px solid #fff;
      border-bottom: 2px solid #fff;
      display: none;
    }
  }
  input:checked + .checker{
    border: 1px solid transparent;
    background-color: $blue;
    &::before{
      display: block;
    }
  }
}
.deliveries{
  margin: -0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0 2.5rem;
  flex-wrap: wrap;
  &_item{
    width: 100%;
    padding: 0.3rem 0.25rem;
  }
}
.delivery{
  padding: 0.7rem;
  border-radius: $border-radius;
  border: 1px solid $gray-400;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 9rem;
  height: 6.8rem;
  &.active{
    border-color: $blue;
  }
  picture{
    img, source{
      width: 2rem;
      height: 2rem;
      margin-bottom: 0.875rem;
    }
  }
  svg{
    width: 2rem;
    height: 2rem;
    margin-bottom: 0.875rem;
  }
  &_sm{
    flex: 0 0 9rem;
    width: unset;
    height: 3.4375rem;
    flex-direction: row;
    justify-content: flex-start;
    svg{
      width: 3.5rem;
      //height: 1.5rem;
      margin-right: 0.5rem;
      margin-bottom: 0;
    }
  }
}
.address{
  display: flex;
  align-items: stretch;
  margin: 0 -0.625rem;
  &_container{
    flex-grow: 1;
    padding: 0 0.625rem;
    &__items{
      padding-top: 1.5rem;
    }
    //&:has( input:focus){
    //  .address_container__items{
    //    display: flex !important;
    //  }
    //}
  }
  &_map{
    width: 21rem;
    padding: 0 0.625rem;
    display: flex;
    align-items: stretch;
    .map{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-radius: 0.625rem;
      overflow: hidden;
    }
    h1{
      background-color: $gray-400;
      min-height: 100%;
      width: 100%;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &_items__wrapper{
  }
  &_item{
    border-radius: $border-radius*2;
    padding: 0.65rem 1.3125rem;
    background-color: $gray-100;
    border: 1px solid transparent;
    &.active{
      border: 1px solid $blue;
    }
    &.checked{
      background-color: $gray !important;
      color: #fff;
    }
    &:nth-child(2n){
      background-color: transparent;
    }
    &__info{
      padding-right: 0.5rem;
      &-num{
        width: max-content;
      }
    }
  }
}
.switcher{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
  input{
    display: none;
  }
  .text{
    &_left{font-weight: 500;}
    &_right{font-weight: 400;}
  }
  .checker{
    width: 1.875rem;
    height: 1rem;
    position: relative;
    background-color: $blue;
    margin: 0 0.875rem;
    border-radius: 1rem;
    &::before{
      content: '';
      position: absolute;
      width: 0.875rem;
      height: 0.875rem;
      background-color: #fff;
      border-radius: 100%;
      left: 1px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &:has(> input:checked){
    .checker{
      &::before{
        left: unset;
        right: 1px;
      }
    }
    .text{
      &_left{font-weight: 400;}
      &_right{font-weight: 500;}
    }

  }

}
.banks_card{
  width: 100%;
  height: 10.375rem;
  padding: 1rem 1rem 1rem 1.25rem;
  border-radius: 0.75rem;
  overflow: hidden;
  background-image: url(../img/banksCartBg.jpg);
  background-size: cover;
  background-position: 0 0;
  color: $white;
  &.after_mark{
    position: relative;
    &::before{
      content:'';
      position: absolute;
      width: 1.375rem;
      height: 1.375rem;
      border-radius: 100%;
      background-color: $white;
      right: 2.8rem;
      top: -0.6875rem;
    }
    &::after{
      content:'';
      position: absolute;
      width: 1.375rem;
      height: 1.375rem;
      border-radius: 100%;
      background-color: $white;
      right: 2.8rem;
      bottom: -0.6875rem
    }
  }
  &__title{
    svg{
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.5rem;
    }
  }
  &__number{
    word-break: break-word;
  }
  &__name{

  }
}
.title_26{
  font-size: $title_26;
}
table{
  width: 100%;
  border-spacing: 0;
  &.table-striped{
    tr{
      background: $gray-500;
      td{
        padding: 1rem;
        font-size: $font-size-md;
        &:first-child{
          border-radius: 0.3125rem 0 0 0.3125rem;
          padding-left: 1.25rem;
        }
        &:last-child{
          border-radius: 0 0.3125rem 0.3125rem 0;
          padding-right: 1.25rem;
        }
      }
      &:nth-child(2n){
        background: $white;
      }
    }
  }
}
.radio_rating{
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 0.375rem;
  border: 1px solid $red;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $font-size-md;
  font-weight: 700;
  input{
    display: none;
  }
  &:has(> input:checked){
    background-color: $red;
    color: $white;
  }
  &.yellow{
    border-color: $yellow;
    &:has(> input:checked){
      background-color: $yellow;
      color: $black;
    }
  }
  &.green{
    border-color: $green-light;
    &:has(> input:checked){
      background-color: $green-light;
    }
  }
}
.color_card{
  padding: 0.75rem 1.25rem 1.25rem;
  box-shadow: 0 0 3.125rem rgba(0, 0, 0, 0.04);
  border-radius: $border-radius*2;
  color: $white;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  &+.color_card{
    margin-top: 1rem;
  }
  &.ukraine{
    position: relative;
    border-radius: 0.625rem;
    overflow: hidden;
    .trident{
      width: 3.6rem;
      margin-right: 1.5rem;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        width: 1px;
        height: 3.75rem;
        background-color: #000;
        right: -1.5rem;
        top: 0.75rem;
      }
    }
    img{
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      &.bottom{
        top: unset;
        bottom: 0;
      }
    }
  }
  &.blue{
    background-image: url(../img/card_blue.jpg);
    background-size: cover;
  }
  &.orange{
    background-image: url(../img/card_orange.jpg);
    background-size: cover;
  }
  &.orange-big{
    background-image: url(../img/card_orange-big.jpg);
    background-size: cover;
  }
  &.blue-big{
    background-image: url(../img/card_blue-big.jpg);
    background-size: cover;
  }
  &.landing{
    .color_card__img{
      right: 1rem;
      bottom: 0.5rem;
      width: 8.1rem;
    }
  }
  &.lg{
    padding: 3.75rem 6rem 3.43rem 6rem;
    display: flex;
    flex-direction: column;
    height: 18.625rem;
    .color_card__title{
      flex-grow: 1;
      width: 30.125rem;
      line-height: 2rem;
    }
    .color_card__img{
      bottom: -2.5rem;
      width: 29rem;
      height: unset!important;
    }
  }
  &.md{
    padding: 3.125rem 4.25rem 3.375rem 4.25rem;
    color: #fff;
    .color_card__title{
      font-size: 3.75rem;
      //line-height: 4.375rem;
      font-weight: 500;
    }
    .color_card__subtitle{
      font-size: 1.9375rem;
      font-weight: 500;
      margin-bottom: 1.5rem;
    }
    .color_card__img{
      width: 27rem;
      right: 5.8125rem;
      top: 0;
    }
  }
  &.sm{
    padding: 1.375rem 1rem 1.375rem 1.5rem;
    height: 9.375rem;
    .color_card{
      &__body{
        flex-grow: 1;
      }
      &__img{
        width: 9rem;
        height: 5rem;
      }
    }
  }
  &__title{
    line-height: 3rem;
    font-size: $h1;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }
  &__body{
    flex-grow: 1;
    .promo{
      &_text{
        font-weight: 500;
        line-height: 1.125rem;
        margin-bottom: 2.25rem;
      }
      &_title{
        font-weight: 500;
        line-height: 3rem;
        letter-spacing: 0.05em;
        font-size: 2.5rem;
      }
    }
  }
  &__footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  &__img{
    position: absolute;
    right: -0.5rem;
    bottom: -0.5rem;
    width: 10rem;
    img{
      object-fit: contain;
      width: 100%;
      min-height: 100%;
    }
  }
}
.article{
  box-shadow: 0 0 3.125rem rgba(0, 0, 0, 0.04);
  &_img{
    width: 100%;
    height: 9.06rem;
    border-radius: $border-radius*2 $border-radius*2 0 0;
    overflow: hidden;
  }
  &_title{
    padding: 1rem 0.5rem;
    text-align: center;
    background-color: #fff;
    border-radius: 0 0 $border-radius*2 $border-radius*2;
    overflow: hidden;
    font-size: $font-size-md;
  }
}
.link_black{
  color: #000;
  font-weight: 500;
  font-size: 1rem;
  &:hover{
    color: #333333;
  }
}
.order_status{
  height: 1.125rem;
  width: max-content;
  padding: 0 0.3125rem;
  display: flex;
  align-items: center;
  background-color: #F0EFEF;
  color: #000;
  border-radius: 2px;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  &.done{
    background-color: #EDFFEC;
    color: $green;
  }
  &.delivered{
    background-color: $green;
    color: #fff;
  }
  &.new, &.processing{
    background-color: #FFFDEC;
    color: $orange;
  }
}
.order{
  padding: 1.25rem;
  display: flex;
  background-color: #fff;
  min-height: 4.625rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.625rem;
  overflow: hidden;
  &+.order{
    margin-top: 0.625rem;
  }
  &_details{
    display: none;
    padding-top: 1.875rem;
    &__history-item{
      padding-bottom: 0.9375rem;
      &:last-child{
        padding-bottom: 0;
      }
      &>div{
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.125rem;
        color: $black;
        &:first-child{
          color: $gray-light;
        }
        &:last-child{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          svg{
            width: 1.125rem;
            height: 1.125rem;
            margin-right: 0.3125rem;
          }
        }
      }
    }
    &__accordion-item{
      display: flex;
      margin-bottom: 0.625rem;
      &>div{
        font-size: $font-size-sm;
        &:first-child{
          flex: 0 0 50%;
          max-width: 50%;
          position: relative;
          padding-left: 0.5rem;
          color: $gray-300;
          &::before{
            content: '';
            width: 0.125rem;
            top: 0;
            bottom: 0;
            position: absolute;
            left: 0;
            background-color: $dark-orange;
          }
        }
        &:last-child{
          flex-grow: 1;
          color: $gray-300;
        }
      }
      &:first-child{
        &>div{
          &:first-child{
            &::before{
              background-color: $green;
            }
          }
          &:last-child{
            color: $black;
          }
        }
      }
      &:nth-child(2){
        &>div{
          &:first-child{
            &::before{
              background-color: $green;
            }
          }
        }
      }
      &:last-child{
        margin-bottom: 1.25rem;
      }
    }
  }
  &_number{
    .number{
      font-weight: 500;
      font-size: 1rem;
      line-height: 100%;
      color: $black;
    }
  }
  &_items{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -0.31235rem;
    //width: 15.8rem;
    div{
      width: 2.3125rem;
      height: 2.3125rem;
      border-radius: 0.3125rem;
      border: 1px solid #C3C3C3;
      margin: 0 0.3125rem;
      overflow: hidden;
      img{
        width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
      .plus{
        svg{
          width: 0.6875rem;
          height: 0.6875rem;
          stroke: currentColor;
        }
      }
    }
  }
  &_address{
    .adress{
      max-width: 14rem;
    }
  }
  &_count{
    //margin-right: 3.125rem;
    .count{
      color: $gray-300;
    }
    .sum{

    }
  }
  &_arrow{
    width: 2rem;
    height: 2rem;
    background-color: $gray-500;
    border-radius: 0.3125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.3125rem;
    svg{
      width: 0.8125rem;
      height: 0.8125rem;
      stroke: $black;
      transition: .3s;
    }
  }
  .separetor{
    margin: 1rem -1rem 0.75rem;
    height: 1px;
    background-color: $gray-400;
  }
  &.show-details{
    .order_items{display: none;}
    .order_count{display: none;}
    .order_address{padding-left: 2.8125rem;}
    .order_details{display: flex;}
    .separetor{
      margin: 0;
      background-color: #fff;
    }
    .order_arrow svg{
      transform: rotateZ(90deg);
    }
  }

}

//slick
.slick-dots{
  margin: 0;
  display: flex;
  justify-content: center;
  button{
    font-size: 0;
    background-color: #D9D9D9;
    border: none;
    box-shadow: none;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
    margin: 0.3125rem;
    padding: 0;
  }
  .slick-active{
    button{
      background-color: #000;
    }
  }
}

.prompt{
  width: 100%;
  position: absolute;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0 0 3.125rem rgba(0, 0, 0, 0.1);
  padding: 1rem 1.5rem;
  border-radius: $border-radius*2;
  left: 0;
  top: 125%;
  opacity: 0;
  pointer-events: none;
  transition: .3s;
  &.show{
    opacity: 1;
    pointer-events: unset;
    top: 120%;
  }
  .scroll_vertical{
    max-height: 40rem;
  }
  &_triangle{
    position: relative;
    &::after{
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 10px 8px;
      border-color: transparent transparent #ffffff transparent;
      top: -1.7rem;
      left: 3.15rem;
    }
  }
}

.alert{
  border-radius: $border-radius;
  padding: 0.3125rem 0.625rem;
  position: relative;
  margin-bottom: 0.75rem;
  min-height: 1.75rem;
  &::before{
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
    top: 1rem;
    left: 1.125rem;
    position: absolute;
  }
  &-danger{
    padding: 0.625rem 0.75rem 0.625rem 2.5rem;
    background-color: $red_opacity;
    &::before{
      background-color: $red;
    }
  }
  &-success{
    background-color: $green-opacity;
    svg{
      width: 1.125rem;
      height: 1.125rem;
      fill: $green;
    }
  }
}

// personal_account
.account{
  &_sidebar{
    &>a{
      border: 1px solid transparent !important;
      &.active{
        border: 1px solid $blue !important;
      }
    }
    &__banner{
      background-image: url(../img/personal_acc/square_orange-bg.jpg);
      background-size: cover;
      padding: 2rem 1.875rem;
      box-shadow: 0 0 3.125rem rgba(0, 0, 0, 0.04);
      border-radius: $border-radius*2;
      color: $white;
    }
  }
}

.sidebar_menu{
  border-radius: $border-radius*2;
  box-shadow: 0 0.25rem3.125rem rgba(0, 0, 0, 0.04);
  background-color: $white;
  padding: 0.3125rem;
  overflow: hidden;
  margin-bottom: 1rem;
  &__item{
    width: 100%;
    height: 3.125rem;
    color: $black;
    font-weight: 400;
    padding-left: 1.25rem;
    padding-right: 1rem;
    display: none;
    align-items: center;
    &.active{
      display: flex;
    }
    &:hover{
      color: $black-light;
    }
    &:last-child{
      border: none;
    }
    //&:nth-child(2n){
    //  background-color: $gray-500;
    //}
  }
  &.show{
    .sidebar_menu__item{
      border-bottom: 1px solid $gray-500;
      display: flex;
      background-color: #fff;
    }
  }
  svg{
    width: 0.625rem;
    height: 0.625rem;
    top: 1.6rem;
    right: 1rem;
  }
}
.promo_copy__svg{
  width: 1.5rem;
  height: 1.5rem;
}
.red_counter{
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 100%;
  font-size: $font-size-sm;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  background-color: $red;
  margin-left: 0.625rem;
}
.radio{
  display: flex;
  align-items: center;
  justify-content: center;
  input{
    display: none;
    &:checked + .radio_checker{
      &::before{
        border: 1px solid $blue;
      }
      &::after{
        background-color: $blue;
      }
    }
  }
  &_checker{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    &::before{
      content: '';
      position: absolute;
      width: 1.125rem;
      height: 1.125rem;
      border-radius: 100%;
      border: 1px solid #DDDDDD;

    }
    &::after{
      content: '';
      position: absolute;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 100%;
      background-color: transparent;
    }
  }
}
.empty_action_slider{
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  margin-right: -1.25rem;
  .product_container__item{
    width: 13.875rem;
    max-width: unset;
  }
}
.promo_copy__svg{
  width: 1.5rem;
  height: 1.5rem;
}
.red_counter{
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 100%;
  font-size: $font-size-sm;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  background-color: $red;
  margin-left: 0.625rem;
}
.radio{
  display: flex;
  align-items: center;
  justify-content: center;
  input{
    display: none;
    &:checked + .radio_checker{
      &::before{
        border: 1px solid $blue;
      }
      &::after{
        background-color: $blue;
      }
    }
  }
  &_checker{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    &::before{
      content: '';
      position: absolute;
      width: 1.125rem;
      height: 1.125rem;
      border-radius: 100%;
      border: 1px solid #DDDDDD;

    }
    &::after{
      content: '';
      position: absolute;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 100%;
      background-color: transparent;
    }
  }
}
.pagination{
  display: flex;
  justify-content: center;
  margin-bottom: 2.25rem;
  &_item{
    cursor: pointer;
    background-color: $white;
    border-radius: 0.25rem;
    border: 1px solid $gray-400;
    color: $black-light;
    width: 2rem;
    height: 2rem;
    margin: 0 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active{
      border-color: $blue-light;
      color: $blue-light;
    }
    &.arrow{
      svg{
        width: 1.5rem;
        height: 1.5rem;
        fill: $gray-400;
      }
    }
    &:hover{
      background-color: $gray-400;
      color: $white;
      &.arrow{
        svg{
          fill: $white;
        }
      }
    }
  }
}
.reviews_block {
  &+.reviews_block{
    margin-top: 1.5rem;
  }
}
.start_white__accordion{
  .accordion{
    background-color: #fff!important;
    &:nth-child(2n){
      background-color: #F9F9FF !important;
    }
  }
}
.icon-cross{
  position: relative;
  width: 0.75rem;
  height: 0.75rem;
  background: url(../img/icon-cross.svg);
  background-size: cover;
  display: block;
  margin-left: 0.75rem;
}
.arrow_status{
  position: absolute;
  top: 0;
  right: 0;
  width: 3.125rem;
  height: 3.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
  svg{
    width: 0.875rem;
    height: 0.875rem;
  }
}
.show{
  .arrow_status{
    transform: rotateZ(180deg);
  }
}
.sidebar_menu__item:nth-child(2n) {
  background-color: #F9F9FF;
}

.title_22{
  font-size: $h1;
}
.w-unset{
  width: unset !important;
}
.pager__item{
  height: 2.5rem !important;
  padding: 0 1rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: unset !important;
  font-size: 1rem !important;
}
.reviews_count {
  padding: 0.25rem 0.5rem;
  border-radius: 0.3125rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  background-color: #FF880E;
}