.contact{
  &_list{
    flex: 0 0 24.2rem !important;
    max-width: 24.2rem !important;
    &__item{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 0.58rem;
      &:last-child{
        margin-bottom: 0;
      }
      svg{
        flex: 0 0 1rem;
        max-width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
      }
    }
  }
  .feedback{
    .form-group{
      padding-bottom: 0;
      margin-bottom: 1.25rem;
    }
  }
  .branch{
    padding: 0;
    &+.branch{
      margin-top: 1.25rem;
    }
    &_img{
      width: 100%;
      height: 14.875rem;
      img{
        width: 100%;
        min-height: 100%;
        object-fit: cover;
      }
    }
    &_title{
      padding: 2.1875rem 0;
      text-align: center;
      font-size: $font-size-lg;
      font-weight: 500;
    }
  }
  .shop_region__list{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -0.4rem 2.8rem;
    &-item{
      width: 10.3rem;
      //height: 10.3rem;
      padding: 0 0.4rem;
      margin-bottom: 0.8rem;
      &--wrapper{
        padding: 0.625rem;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &--img{
        width: 7.625rem;
        //height: 4.87rem;
        img{
          width: 100%;
          min-height: 100%;
          object-fit: contain;
        }
      }
      &--title{
        padding-top: 0.75rem;
        font-size: $font-size-md;
        font-weight: 500;
      }
    }
  }
}