.catalog{
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  &_sidebar{
    padding-bottom: 0.5rem;
  }
  &_content{
    padding-top: 0;
    .articles_slider__item{
      width: 100%;
    }
  }
  .articles_slider__item{
    margin-bottom: 1.25rem;
    display: flex;
    flex-direction: column;
    .article{
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      &_title{
        justify-content: center;
        display: flex;
        align-items: center;
        padding: 1.5rem 2rem;
        flex-grow: 1;
        font-size: $font-size-lg;
      }
      &_img{
        height: 13.32rem;
        img{
          min-height: 100%;
        }
      }
    }
  }
}