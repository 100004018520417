@import "../plugins/slick/css/slick.css";
@import "../plugins/toastr/toastr.css";
html, body {
  font-size: 4.44vw;
}

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #F8F8FE;
  counter-reset: section;
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}
body * {
  box-sizing: border-box;
}
body ul {
  padding-left: unset;
}
body ul li {
  list-style: none;
}
body h1, body h2, body h3, body h4 {
  margin: 0;
}
body input::-webkit-outer-spin-button,
body input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
body input[type=number] {
  -moz-appearance: textfield;
}
body .show {
  display: block !important;
}
body .row {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
  display: flex;
  flex-wrap: wrap;
}
body .col, body .col-1, body .col-10, body .col-11, body .col-12, body .col-2, body .col-3, body .col-4, body .col-5, body .col-6, body .col-7, body .col-8, body .col-9, body .col-auto, body .col-lg, body .col-lg-1, body .col-lg-10, body .col-lg-11, body .col-lg-12, body .col-lg-2, body .col-lg-3, body .col-lg-4, body .col-lg-5, body .col-lg-6, body .col-lg-7, body .col-lg-8, body .col-lg-9, body .col-lg-auto, body .col-md, body .col-md-1, body .col-md-10, body .col-md-11, body .col-md-12, body .col-md-2, body .col-md-3, body .col-md-4, body .col-md-5, body .col-md-6, body .col-md-7, body .col-md-8, body .col-md-9, body .col-md-auto, body .col-sm, body .col-sm-1, body .col-sm-10, body .col-sm-11, body .col-sm-12, body .col-sm-2, body .col-sm-3, body .col-sm-4, body .col-sm-5, body .col-sm-6, body .col-sm-7, body .col-sm-8, body .col-sm-9, body .col-sm-auto, body .col-xl, body .col-xl-1, body .col-xl-10, body .col-xl-11, body .col-xl-12, body .col-xl-2, body .col-xl-3, body .col-xl-4, body .col-xl-5, body .col-xl-6, body .col-xl-7, body .col-xl-8, body .col-xl-9, body .col-xl-auto {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
@media (min-width: 768px) {
  body .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
body .col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
body .col-4 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
}
body .col-8 {
  flex: 0 0 66.66%;
  max-width: 66.66%;
}
body .offset-md-4 {
  margin-left: 33.33%;
}
body .backdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  pointer-events: none;
  transition: 0.3s;
}
body.modal_show .backdrop {
  pointer-events: unset;
  background-color: rgba(0, 0, 0, 0.5);
}
body .overflow-auto {
  overflow: auto;
}
body textarea {
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  padding: 1rem 1rem 0 1rem;
  margin: 1rem 0;
  background-color: transparent;
  border-radius: 0.3125rem;
}
body textarea.disabled {
  padding-left: 0;
  padding-right: 0;
  border-color: transparent;
  pointer-events: none;
  margin: 0;
}

a {
  color: #333;
  text-decoration: none;
}
a:hover {
  color: #000;
}
a.blue {
  color: #0F8CFF;
}
a.blue:hover {
  color: #0F74E4;
}

.container {
  width: 100%;
}

.container_fluid {
  width: 100%;
  padding: 0 1.25rem;
}

.header_container {
  padding: 0 1.25rem;
  width: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

.brdr-0 {
  border-radius: 0 !important;
}

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.w-100 {
  width: 100% !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.pt-4 {
  padding-top: 2rem !important;
}

.mt-4 {
  margin-top: 2rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 2rem !important;
}

.mb-4 {
  margin-bottom: 2rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.pl-4 {
  padding-left: 2rem !important;
}

.ml-4 {
  margin-left: 2rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.pr-4 {
  padding-right: 2rem !important;
}

.mr-4 {
  margin-right: 2rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.w-100 {
  width: 100%;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.font_400 {
  font-weight: 400 !important;
}

.font_500 {
  font-weight: 500 !important;
}

.font_600 {
  font-weight: 600 !important;
}

.font_700 {
  font-weight: 700 !important;
}

.text-base {
  font-size: 1rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
}

.text-lg {
  font-size: 1.125rem !important;
}

.text-md {
  font-size: 0.875rem !important;
}

.text-sm {
  font-size: 0.75rem !important;
}

.text-xs {
  font-size: 0.625rem !important;
}

.text-center {
  text-align: center;
}

.text-white {
  color: #fff !important;
}

.text-blue {
  color: #0F8CFF !important;
}

.text-danger {
  color: #FF0000 !important;
}

.text-violet {
  color: #7360F2 !important;
}

.text-gray {
  color: #767676 !important;
}

.text-gray200 {
  color: #B7B7BA !important;
}

.text-black {
  color: #000 !important;
}

.text-black-light {
  color: #292929 !important;
}

.text-green {
  color: #009217 !important;
}

.lh-1 {
  line-height: 1rem !important;
}

.lh-2 {
  line-height: 1.125rem !important;
}

.lh-3 {
  line-height: 1.25rem !important;
}

.lh-4 {
  line-height: 1.5rem !important;
}

.h1 {
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 1.625rem;
}

.text-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.btn {
  height: 3.125rem;
  padding: 0 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
}
.btn-primary, .btn--primary {
  background-color: #0F8CFF;
  font-size: 1.125rem;
  color: #fff !important;
  border: none;
}
.btn-primary svg, .btn--primary svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: #fff;
  margin-right: 0.5rem;
}
.btn-secondary, .btn--icon-left {
  background-color: #F7F7FC;
  color: #000;
  height: 2.5rem;
}
.btn-secondary svg, .btn--icon-left svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: #0F8CFF;
  margin-right: 0.5rem;
}
.btn--primary, .btn--icon-left {
  height: 2.625rem;
  border: none;
  flex-grow: 1;
}
.btn_gray {
  background-color: #F7F7FC;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0 1.25rem;
}
.btn_gray.lg {
  height: 3.125rem;
  padding: 0 2rem;
  font-weight: 600;
}
.btn_gray.md {
  height: 1.625rem;
  padding: 0 0.5rem;
  font-weight: 500;
}
.btn_gray:hover {
  opacity: 0.95;
}
.btn_gray svg {
  width: 1.56rem;
  height: 1.56rem;
  fill: #0F8CFF;
}
.btn-white {
  background-color: #fff;
  color: #000;
  padding: 0 1.25rem;
  height: 2.375rem;
  font-size: 0.875rem;
  width: max-content;
}
.btn.btn-primary-outline {
  height: 3.125rem;
  font-size: 1rem;
  line-height: 2.375rem;
  border: 1px solid #0F8CFF;
  color: #0F8CFF;
  background-color: transparent;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: max-content;
}
.btn.btn-primary-outline:hover {
  background-color: #fff;
}
.btn.btn-primary-outline svg {
  width: 1.375rem;
  height: 1.375rem;
  fill: #0F8CFF;
}
.btn.btn-lg {
  height: 3.94rem;
}
.btn.btn_md {
  height: 2.875rem;
  width: max-content;
}
.btn.btn_sm {
  padding: 0 1.25rem;
  height: 2.25rem;
  width: max-content;
}
.btn.btn_xs {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  height: 1.375rem;
}
.btn_sities {
  margin: 0 -0.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.btn_sities__item {
  padding: 0 0.25rem;
  margin-bottom: 0.5rem;
  flex-grow: 1;
}
.btn_sities__item a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.75rem;
  padding: 0 0.75rem;
  border-radius: 0.3125rem;
  background-color: #F7F7FC;
  border: 1px solid transparent;
  font-size: 0.875rem;
  color: #000000;
}
.btn_sities__item a.active {
  border: 1px solid #0F8CFF;
}

.star_rating {
  display: flex;
  align-items: center;
}
.star_rating .stars_wrapper {
  padding: 0 0.5rem;
}
.star_rating .stars_wrapper__active {
  overflow: hidden;
  top: 0;
}
.star_rating .stars_wrapper svg {
  fill: #767676;
  width: 1rem;
  height: 1rem;
}
.star_rating .stars_wrapper svg.set_rating {
  pointer-events: none;
}
.star_rating .stars_wrapper svg.set_rating:first-child {
  pointer-events: unset;
}
.star_rating .stars_wrapper svg.set_rating use:first-child {
  display: none;
}
.star_rating .stars_wrapper svg.set_rating use:last-child {
  display: block;
}
.star_rating .stars_wrapper svg.set_rating.check + .set_rating {
  pointer-events: unset;
}
.star_rating .stars_wrapper svg.set_rating.check use:first-child {
  display: block;
}
.star_rating .stars_wrapper svg.set_rating.check use:last-child {
  display: none;
}
.star_rating.sm svg {
  width: 0.9375rem;
  height: 0.875rem;
}
.star_rating.xs svg {
  width: 0.625rem;
  height: 0.625rem;
}

.note {
  padding: 0.5rem 0.875rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.note + .note {
  margin-top: 1rem;
}
.note svg {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.625rem;
}
.note-orange {
  background-color: #FC7F38;
  color: #fff;
}
.note-green {
  background-color: #EDFFEC;
  color: #009217;
  font-size: 0.75rem;
}
.note-green svg {
  fill: #009217;
}
.note-gray {
  background-color: #F7F7FC;
  color: #464646;
  font-size: 0.75rem;
}
.note-gray svg {
  fill: #0F8CFF;
}

.banner {
  padding: 1.25rem;
  background-size: cover;
  border-radius: 0.625rem;
  overflow: hidden;
  background-image: url(../img/banner_orange.jpg);
}
.banner-wrapper {
  padding: 1.5rem 0;
}
.banner.blue {
  background-image: url(../img/banner_blue.jpg);
}
.banner.banner_lg {
  background-image: url(../img/banner_lg.jpg);
  background-size: cover;
  border-radius: 0.625rem;
}
.banner.banner_lg .text {
  width: 70%;
}
.banner .text {
  width: 63%;
}

.accordion {
  background-color: #F9F9FF;
  border-radius: 0.3125rem;
}
.accordion:nth-child(2n) {
  background-color: #fff !important;
}
.accordion_item {
  color: #000;
  cursor: pointer;
  padding: 1.5rem 1.25rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  transition: 0.4s;
}
.accordion_item__icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.875rem;
}
.accordion_item__title {
  font-weight: 500;
}
.accordion_item__marker {
  flex: 0 0 1.125rem;
  max-width: 1.125rem;
  height: 1.125rem;
  margin-left: 1rem;
}
.accordion_item__marker.plus {
  display: block;
}
.accordion_item__marker.minus {
  display: none;
}
.accordion_item.active .accordion_item__marker.plus {
  display: none;
}
.accordion_item.active .accordion_item__marker.minus {
  display: block;
}

.panel {
  padding: 0 1.75rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.panel_text {
  position: relative;
  margin-bottom: 1rem;
}
.panel_text::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0.375rem;
  border-radius: 100%;
}
.panel_text._black {
  padding-left: 1.5rem;
}
.panel_text._black::after {
  width: 0.25rem;
  height: 0.25rem;
  background-color: #000;
}
.panel_text._green {
  padding-left: 1.5rem;
}
.panel_text._green::after {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #009217;
}
.panel_text._red {
  padding-left: 1.5rem;
}
.panel_text._red::after {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #FF0000;
}

.info_item {
  padding: 1.5rem 1.375rem;
  background-color: #F7F7FC;
  border-radius: 0.3125rem;
}
.info_item__title {
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 500;
}
.info_item__title svg {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
}
.info_item:nth-child(2n) {
  background-color: #fff;
}
.info_sm .info_item {
  padding: 1rem 1.25rem;
  background-color: #F7F7FC;
  border-radius: 0.3125rem;
  font-size: 1rem;
  align-items: flex-start;
}
.info_sm .info_item.hide {
  display: none;
}
.info_sm .info_item > div > div:last-child {
  max-width: 15.31rem;
  text-align: right;
  font-weight: 500;
}
.info_sm .info_item .dashed {
  border-bottom: 1px dashed #9A9A9A;
  flex-grow: 1;
  margin: 0 0.5rem;
  height: 1.125rem;
}
.info_sm .info_item:nth-child(2n) {
  background-color: #fff;
}
.info_show__more svg {
  width: 1.6rem;
  height: 1.6rem;
  transition: 0.3s;
}
.info_show__more.more svg {
  transform: rotateZ(180deg);
  transition: 0.3s;
}

.card {
  padding: 1rem 1.25rem;
  background-color: #fff;
  border-radius: 0.625rem;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.04);
}
.card h4 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.card h4 span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
  flex: 0 0 2rem;
  max-width: 2rem;
  height: 2rem;
  border-radius: 100%;
  background-color: #F0F0FE;
  margin-right: 0.75rem;
}
.card + .card {
  margin-top: 0.625rem;
}
.card_body svg {
  width: 1.5rem;
  height: 1.25rem;
}
.card_body svg.icon-hint {
  fill: #767676;
}

.person {
  background-color: #F7F7FC;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  padding: 0.75rem 0.875rem;
  max-width: 25rem;
}
.person_img {
  flex: 0 0 2.875rem;
  max-width: 2.875rem;
  height: 2.875rem;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 0.5rem;
}
.person_img img {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
}
.person_text {
  color: #000;
  font-size: 0.875rem;
  font-weight: 400;
}

.share {
  display: flex;
  align-items: center;
}
.share svg {
  flex: 0 0 1.25rem;
  max-width: 1.25rem;
  height: 0.875rem;
  fill: #0F8CFF;
  margin-right: 0.25rem;
}
.share .title {
  font-size: 0.875rem;
}
.share_icons {
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0 0 3.125rem rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  width: 9.375rem;
  top: 100%;
  right: 0;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  transform: translateY(1rem);
  transition: 0.3s;
}
.share_icons.visible {
  opacity: 1;
  pointer-events: unset;
  transform: translateY(0);
}
.share_icons a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.625rem;
  font-weight: 400;
  padding: 0.5rem 0;
}
.share_icons a:first-child {
  padding-top: 0;
}
.share_icons a:last-child {
  padding-bottom: 0;
}
.share_icons a svg {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.reviews_slider {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: auto;
}
.reviews_slider::-webkit-scrollbar {
  height: 3px;
}
.reviews_slider::-webkit-scrollbar-thumb {
  background: #A6A6A6;
  border-radius: 0.25rem;
}
.reviews_slider::-webkit-scrollbar-track {
  background: #F0F0F0;
}
.reviews_slider .gray_block {
  flex: 0 0 16.81rem;
  max-width: 16.81rem;
}
.reviews_slider__item {
  height: 11rem;
  overflow: hidden;
}
.reviews_slider__item.show {
  height: unset !important;
}
.reviews_slider__item-head {
  padding-bottom: 0.875rem;
  display: flex;
  justify-content: space-between;
}
.reviews_slider__item .review_date {
  color: #767676;
  font-size: 0.625rem;
}
.reviews_slider__item .review_title {
  color: #000;
  font-size: 0.875rem;
  font-weight: 500;
}

.modal {
  position: fixed;
  opacity: 1;
  transition: 0.3s;
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 90%;
  max-width: 23.75rem;
  margin: 0 auto;
  max-height: 100%;
}
.modal .modal-dialog {
  width: 100%;
  background-color: #fff;
  padding: 2rem;
  border-radius: 0.625rem;
  margin-top: 0;
  transition: 0.3s;
  position: relative;
}
.modal .modal-dialog .close {
  position: absolute;
  top: 1.125rem;
  right: 1.25rem;
  width: 0.9375rem;
  height: 0.9375rem;
  cursor: pointer;
}
.modal .modal-dialog .big_title {
  font-size: 1.625rem;
  font-weight: 400;
  line-height: 1.25rem;
}
.modal.fade {
  opacity: 0;
  transition: 0.3s;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  margin-top: 1.5rem;
}
.modal-content {
  padding-top: 1.5rem;
  overflow: auto;
  height: 100%;
}
.modal-footer {
  padding-top: 1.5rem;
}

.widget {
  padding-top: 2rem;
  background-color: #f7f7f7;
}
.widget_title {
  padding-bottom: 1.25rem;
  font-size: 1.375rem;
  font-weight: 0;
}

.product_item {
  background-color: #fff;
  border-radius: 0.625rem;
  overflow: hidden;
  flex: 0 0 9.8125rem;
  max-width: 9.8125rem;
  margin-right: 0.375rem;
}
.product_item-img {
  width: 100%;
  height: 6.94rem;
}
.product_item-img img {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.product_item-info {
  padding: 0 0.75rem 1rem 0.75rem;
}
.product_item-info .desc {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.9rem;
  margin-bottom: 1rem;
}
.product_footer {
  display: flex;
  justify-content: space-between;
}
.product_footer .sum {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
}
.product_footer .cart {
  background-color: #0F8CFF;
  width: 1.875rem;
  height: 1.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
}
.product_footer .cart svg {
  fill: #fff;
  width: 1.125rem;
  height: 1.125rem;
}

.scroll_horisontal {
  overflow-x: auto;
  overflow-y: hidden;
}
.scroll_horisontal::-webkit-scrollbar {
  height: 3px;
}
.scroll_horisontal::-webkit-scrollbar-thumb {
  background: #A6A6A6;
  border-radius: 0.3125rem;
}
.scroll_horisontal::-webkit-scrollbar-track {
  background: #F0F0F0;
}
.scroll_vertical {
  overflow-x: hidden;
  overflow-y: auto;
}
.scroll_vertical::-webkit-scrollbar {
  width: 3px;
}
.scroll_vertical::-webkit-scrollbar-thumb {
  background: #A6A6A6;
  border-radius: 0.3125rem;
}
.scroll_vertical::-webkit-scrollbar-track {
  background: #F0F0F0;
}

.horisontal_scroll {
  overflow: auto;
}
.horisontal_scroll::-webkit-scrollbar {
  height: 3px;
}
.horisontal_scroll::-webkit-scrollbar-thumb {
  background: #A6A6A6;
  border-radius: 0.25rem;
}
.horisontal_scroll::-webkit-scrollbar-track {
  background: #F0F0F0;
}

.input-group {
  position: relative;
  margin-bottom: 0.625rem;
}
.input-group textarea {
  padding-top: 0.5rem;
  height: 7.5rem !important;
}
.input-group .form-control {
  background-color: #F9F9FF;
  border: 1px solid #F1EFEF;
  height: 2.375rem;
  padding: 0 2.5rem 0 1rem;
  color: #000;
  border-radius: 0.5rem;
  outline: none;
}
.input-group .form-control:focus-visible, .input-group .form-control:focus, .input-group .form-control:active {
  border-color: #0F8CFF;
}
.input-group .form-control::placeholder {
  color: #767676;
}
.input-group-append {
  position: absolute;
  top: 0.625rem;
  right: 1.25rem;
}
.input-group-append svg {
  width: 1.125rem;
  height: 1.125rem;
}

.sity {
  margin: 0 -0.25rem;
  display: flex;
}
.sity-item {
  padding: 0 0.25rem;
  flex-grow: 1;
}

.shop {
  margin: 0 -0.3125rem;
  display: flex;
  flex-wrap: wrap;
}
.shop-item {
  padding: 0 0.3125rem 0.625rem;
  width: 50%;
}

.gray_block {
  background: #F7F7FC;
  border-radius: 0.3125rem;
  padding: 1.5rem 2rem;
}
.gray_block__md {
  padding: 1.25rem;
}
.gray_block__sm {
  padding: 0.75rem 0.8125rem;
  color: #000;
  font-size: 0.875rem;
}

.phone {
  padding: 0 1.125rem;
}
.phone > a {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
}
.phone > a svg {
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
}

.js-dropdown {
  position: relative;
  min-width: 2.5rem;
}

.wrapper_dropdown {
  top: 100%;
  position: absolute;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: -2px 5px 15px -8px rgba(0, 0, 0, 0.56);
  width: 100%;
  pointer-events: none;
  opacity: 0;
  transform: translateY(0.5rem);
  transition: 0.3s;
  z-index: 2;
}
.wrapper_dropdown.visible {
  pointer-events: unset;
  opacity: 1;
  transition: 0.3s;
  transform: translateY(0);
}
.wrapper_dropdown__item {
  font-size: 0.75rem;
  color: #000;
  border-radius: 0.25rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
}
.wrapper_dropdown__item:hover {
  color: #fff;
  background-color: #5cb1ff;
}

.tiangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.3125rem 0.156rem 0 0.156rem;
  border-color: #fff transparent transparent transparent;
  margin-left: 0.34rem;
}
.tiangle.black {
  border-color: #000 transparent transparent;
}

.mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
}
.mask.show {
  opacity: 1;
  pointer-events: unset;
}

.sum {
  font-weight: 500;
  font-size: 1.75rem;
}
.sum .sum_text {
  font-size: 1.25rem;
}
.sum .old_price {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  color: #9F9F9F;
  text-decoration: line-through;
}
.sum .discount {
  padding: 0.125rem;
  color: #fff;
  background-color: #FC7F38;
  font-size: 0.5rem;
  line-height: 0.5625rem;
  font-weight: 500;
  border-radius: 0.125rem;
}

.bay_block {
  display: flex;
  margin: 0 -0.4375rem;
  padding-bottom: 1rem;
}
.bay_block.last {
  border-bottom: 1px solid #EDEDED;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}
.bay_block_item {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 0.4375rem;
}
.bay_block_count {
  background-color: #F9F9FF;
  border-radius: 0.25rem;
  padding: 0.5rem 0;
  height: 2.25rem;
}
.bay_block_count .count_symbol {
  font-size: 1rem;
  color: #000;
  font-weight: 400;
  padding: 0 0.25rem 0 0.75rem;
  cursor: pointer;
}
.bay_block_count .count_symbol.left {
  padding: 0 0.75rem 0 0.25rem;
}
.bay_block_count input {
  width: 1.5rem;
  border: none;
  background-color: transparent;
  text-align: center;
}

.available {
  font-size: 0.625rem;
  font-weight: 500;
  color: #000;
}
.available svg {
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.1875rem;
  fill: #009217;
}

.not-available {
  font-size: 0.625rem;
  font-weight: 500;
  color: #B7B7BA;
}
.not-available svg {
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.1875rem;
  fill: #B7B7BA;
}

.alert {
  border-radius: 5px;
  padding: 0.625rem 0.75rem 0.625rem 2.5rem;
  position: relative;
  margin-bottom: 0.75rem;
}
.alert::before {
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
  top: 1rem;
  left: 1.125rem;
  position: absolute;
}
.alert-danger {
  background-color: #FFF1F1;
}
.alert-danger::before {
  background-color: #FF0000;
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.3125rem 0.1875rem 0 0.1875rem;
  border-color: #000 transparent transparent transparent;
  margin-left: 0.5rem;
}
.triangle.big {
  border-width: 0.4375rem 0.25rem 0 0.25rem;
  border-color: #000 transparent transparent transparent;
}
.triangle.big.top {
  border-width: 0 0.25rem 0.4375rem 0.25rem;
  border-color: transparent transparent #000 transparent;
}

.form-group {
  padding-bottom: 0.3125rem;
  position: relative;
}
.form-group + .form-group {
  padding-top: 1.5rem;
}
.form-group.error label {
  color: #FF0000;
}
.form-group.error .form-control {
  border: 1px solid #FF0000;
}
.form-group label {
  display: block;
  font-weight: 400;
  color: #000;
  padding-bottom: 0.625rem;
}
.form-group .form-control {
  width: 100%;
  height: 3.375rem;
  padding: 0 5rem 0 1.125rem;
  border-radius: 0.3125rem;
  border: 1px solid #DDDDDD;
  background-color: #fff;
  resize: vertical;
}
.form-group .form-control:focus-visible {
  outline: none !important;
  border: 1px solid #0F8CFF;
}
.form-group .form-control.sm {
  height: 2.875rem;
}
.form-group .form-control.form_sm {
  height: 2.5rem;
}
.form-group .form-control.error {
  border-color: #FF0000;
}
.form-group .error-text {
  position: absolute;
  bottom: -1rem;
  left: 0;
  font-size: 0.75rem;
  color: #FF0000;
}
.form-group select.form-control {
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right;
  -webkit-appearance: none;
  background-position: 97%;
  background-size: 0.75rem;
}
.form-group .input-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.form-group .input-group .form-error {
  color: #FF0000;
}
.form-group .input-group .form-error p {
  margin: 0.5rem 0;
}
.form-group .input-group-prepend {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.form-group .input-group-prepend .btn {
  width: 3.875rem;
  height: 3.375rem;
  position: absolute;
  top: 0;
  border-radius: 0 0.3125rem 0.3125rem 0;
  right: 0;
  background-color: #DDDDDD;
  font-size: 1rem;
  pointer-events: none;
}
.form-group .input-group-prepend .icon {
  width: 3.875rem;
  height: 3.375rem;
  position: absolute;
  top: 0;
  border-radius: 0 0.3125rem 0.3125rem 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-group .input-group-prepend .icon svg {
  width: 1.25rem;
  height: 1rem;
  fill: #0F8CFF;
}
.form-group .input-group-prepend .icon svg:first-child {
  display: none;
}
.form-group .input-group-prepend .icon svg:last-child {
  display: block;
}
.form-group .input-group-prepend .icon.visible svg {
  fill: #DDDDDD;
}
.form-group .input-group-prepend .icon.visible svg:first-child {
  display: block;
}
.form-group .input-group-prepend .icon.visible svg:last-child {
  display: none;
}
.form-group .input-group .form-control.active + .input-group-prepend .btn {
  background-color: #0F8CFF;
  pointer-events: unset;
}
.form-group .text-sm {
  color: #B7B7BA;
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.checkbox input {
  display: none;
}
.checkbox .checker {
  flex: 0 0 1.375rem;
  max-width: 1.375rem;
  height: 1.375rem;
  border-radius: 0.3125rem;
  border: 1px solid #DDDDDD;
  margin-right: 0.75rem;
  position: relative;
}
.checkbox .checker::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 44%;
  transform: translate(-50%, -50%) rotateZ(-45deg);
  width: 0.75rem;
  height: 0.325rem;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  display: none;
}
.checkbox input:checked + .checker {
  border: 1px solid transparent;
  background-color: #0F8CFF;
}
.checkbox input:checked + .checker::before {
  display: block;
}

.deliveries {
  margin: -0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0 2.5rem;
  flex-wrap: wrap;
}
.deliveries_item {
  width: 100%;
  padding: 0.3rem 0.25rem;
}

.delivery {
  padding: 0.7rem;
  border-radius: 0.3125rem;
  border: 1px solid #DDDDDD;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 9rem;
  height: 6.8rem;
}
.delivery.active {
  border-color: #0F8CFF;
}
.delivery picture img, .delivery picture source {
  width: 2rem;
  height: 2rem;
  margin-bottom: 0.875rem;
}
.delivery svg {
  width: 2rem;
  height: 2rem;
  margin-bottom: 0.875rem;
}
.delivery_sm {
  flex: 0 0 9rem;
  width: unset;
  height: 3.4375rem;
  flex-direction: row;
  justify-content: flex-start;
}
.delivery_sm svg {
  width: 3.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0;
}

.address {
  display: flex;
  align-items: stretch;
  margin: 0 -0.625rem;
}
.address_container {
  flex-grow: 1;
  padding: 0 0.625rem;
}
.address_container__items {
  padding-top: 1.5rem;
}
.address_map {
  width: 21rem;
  padding: 0 0.625rem;
  display: flex;
  align-items: stretch;
}
.address_map .map {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 0.625rem;
  overflow: hidden;
}
.address_map h1 {
  background-color: #DDDDDD;
  min-height: 100%;
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.address_item {
  border-radius: 0.625rem;
  padding: 0.65rem 1.3125rem;
  background-color: #F7F7FC;
  border: 1px solid transparent;
}
.address_item.active {
  border: 1px solid #0F8CFF;
}
.address_item.checked {
  background-color: #333333 !important;
  color: #fff;
}
.address_item:nth-child(2n) {
  background-color: transparent;
}
.address_item__info {
  padding-right: 0.5rem;
}
.address_item__info-num {
  width: max-content;
}

.switcher {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
}
.switcher input {
  display: none;
}
.switcher .text_left {
  font-weight: 500;
}
.switcher .text_right {
  font-weight: 400;
}
.switcher .checker {
  width: 1.875rem;
  height: 1rem;
  position: relative;
  background-color: #0F8CFF;
  margin: 0 0.875rem;
  border-radius: 1rem;
}
.switcher .checker::before {
  content: "";
  position: absolute;
  width: 0.875rem;
  height: 0.875rem;
  background-color: #fff;
  border-radius: 100%;
  left: 1px;
  top: 50%;
  transform: translateY(-50%);
}
.switcher:has(> input:checked) .checker::before {
  left: unset;
  right: 1px;
}
.switcher:has(> input:checked) .text_left {
  font-weight: 400;
}
.switcher:has(> input:checked) .text_right {
  font-weight: 500;
}

.banks_card {
  width: 100%;
  height: 10.375rem;
  padding: 1rem 1rem 1rem 1.25rem;
  border-radius: 0.75rem;
  overflow: hidden;
  background-image: url(../img/banksCartBg.jpg);
  background-size: cover;
  background-position: 0 0;
  color: #fff;
}
.banks_card.after_mark {
  position: relative;
}
.banks_card.after_mark::before {
  content: "";
  position: absolute;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 100%;
  background-color: #fff;
  right: 2.8rem;
  top: -0.6875rem;
}
.banks_card.after_mark::after {
  content: "";
  position: absolute;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 100%;
  background-color: #fff;
  right: 2.8rem;
  bottom: -0.6875rem;
}
.banks_card__title svg {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
}
.banks_card__number {
  word-break: break-word;
}
.title_26 {
  font-size: 1.625rem;
}

table {
  width: 100%;
  border-spacing: 0;
}
table.table-striped tr {
  background: #F9F9FF;
}
table.table-striped tr td {
  padding: 1rem;
  font-size: 0.875rem;
}
table.table-striped tr td:first-child {
  border-radius: 0.3125rem 0 0 0.3125rem;
  padding-left: 1.25rem;
}
table.table-striped tr td:last-child {
  border-radius: 0 0.3125rem 0.3125rem 0;
  padding-right: 1.25rem;
}
table.table-striped tr:nth-child(2n) {
  background: #fff;
}

.radio_rating {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 0.375rem;
  border: 1px solid #FF0000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 700;
}
.radio_rating input {
  display: none;
}
.radio_rating:has(> input:checked) {
  background-color: #FF0000;
  color: #fff;
}
.radio_rating.yellow {
  border-color: #FFD200;
}
.radio_rating.yellow:has(> input:checked) {
  background-color: #FFD200;
  color: #000;
}
.radio_rating.green {
  border-color: #38BC5A;
}
.radio_rating.green:has(> input:checked) {
  background-color: #38BC5A;
}

.color_card {
  padding: 0.75rem 1.25rem 1.25rem;
  box-shadow: 0 0 3.125rem rgba(0, 0, 0, 0.04);
  border-radius: 0.625rem;
  color: #fff;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.color_card + .color_card {
  margin-top: 1rem;
}
.color_card.ukraine {
  position: relative;
  border-radius: 0.625rem;
  overflow: hidden;
}
.color_card.ukraine .trident {
  width: 3.6rem;
  margin-right: 1.5rem;
  position: relative;
}
.color_card.ukraine .trident::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 3.75rem;
  background-color: #000;
  right: -1.5rem;
  top: 0.75rem;
}
.color_card.ukraine img {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.color_card.ukraine img.bottom {
  top: unset;
  bottom: 0;
}
.color_card.blue {
  background-image: url(../img/card_blue.jpg);
  background-size: cover;
}
.color_card.orange {
  background-image: url(../img/card_orange.jpg);
  background-size: cover;
}
.color_card.orange-big {
  background-image: url(../img/card_orange-big.jpg);
  background-size: cover;
}
.color_card.blue-big {
  background-image: url(../img/card_blue-big.jpg);
  background-size: cover;
}
.color_card.landing .color_card__img {
  right: 1rem;
  bottom: 0.5rem;
  width: 8.1rem;
}
.color_card.lg {
  padding: 3.75rem 6rem 3.43rem 6rem;
  display: flex;
  flex-direction: column;
  height: 18.625rem;
}
.color_card.lg .color_card__title {
  flex-grow: 1;
  width: 30.125rem;
  line-height: 2rem;
}
.color_card.lg .color_card__img {
  bottom: -2.5rem;
  width: 29rem;
  height: unset !important;
}
.color_card.md {
  padding: 3.125rem 4.25rem 3.375rem 4.25rem;
  color: #fff;
}
.color_card.md .color_card__title {
  font-size: 3.75rem;
  font-weight: 500;
}
.color_card.md .color_card__subtitle {
  font-size: 1.9375rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
}
.color_card.md .color_card__img {
  width: 27rem;
  right: 5.8125rem;
  top: 0;
}
.color_card.sm {
  padding: 1.375rem 1rem 1.375rem 1.5rem;
  height: 9.375rem;
}
.color_card.sm .color_card__body {
  flex-grow: 1;
}
.color_card.sm .color_card__img {
  width: 9rem;
  height: 5rem;
}
.color_card__title {
  line-height: 3rem;
  font-size: 1.375rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
.color_card__body {
  flex-grow: 1;
}
.color_card__body .promo_text {
  font-weight: 500;
  line-height: 1.125rem;
  margin-bottom: 2.25rem;
}
.color_card__body .promo_title {
  font-weight: 500;
  line-height: 3rem;
  letter-spacing: 0.05em;
  font-size: 2.5rem;
}
.color_card__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.color_card__img {
  position: absolute;
  right: -0.5rem;
  bottom: -0.5rem;
  width: 10rem;
}
.color_card__img img {
  object-fit: contain;
  width: 100%;
  min-height: 100%;
}

.article {
  box-shadow: 0 0 3.125rem rgba(0, 0, 0, 0.04);
}
.article_img {
  width: 100%;
  height: 9.06rem;
  border-radius: 0.625rem 0.625rem 0 0;
  overflow: hidden;
}
.article_title {
  padding: 1rem 0.5rem;
  text-align: center;
  background-color: #fff;
  border-radius: 0 0 0.625rem 0.625rem;
  overflow: hidden;
  font-size: 0.875rem;
}

.link_black {
  color: #000;
  font-weight: 500;
  font-size: 1rem;
}
.link_black:hover {
  color: #333333;
}

.order_status {
  height: 1.125rem;
  width: max-content;
  padding: 0 0.3125rem;
  display: flex;
  align-items: center;
  background-color: #F0EFEF;
  color: #000;
  border-radius: 2px;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
}
.order_status.done {
  background-color: #EDFFEC;
  color: #009217;
}
.order_status.delivered {
  background-color: #009217;
  color: #fff;
}
.order_status.new, .order_status.processing {
  background-color: #FFFDEC;
  color: #FF880E;
}

.order {
  padding: 1.25rem;
  display: flex;
  background-color: #fff;
  min-height: 4.625rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.625rem;
  overflow: hidden;
}
.order + .order {
  margin-top: 0.625rem;
}
.order_details {
  display: none;
  padding-top: 1.875rem;
}
.order_details__history-item {
  padding-bottom: 0.9375rem;
}
.order_details__history-item:last-child {
  padding-bottom: 0;
}
.order_details__history-item > div {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.125rem;
  color: #000;
}
.order_details__history-item > div:first-child {
  color: #767676;
}
.order_details__history-item > div:last-child {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.order_details__history-item > div:last-child svg {
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.3125rem;
}
.order_details__accordion-item {
  display: flex;
  margin-bottom: 0.625rem;
}
.order_details__accordion-item > div {
  font-size: 0.75rem;
}
.order_details__accordion-item > div:first-child {
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  padding-left: 0.5rem;
  color: #9F9F9F;
}
.order_details__accordion-item > div:first-child::before {
  content: "";
  width: 0.125rem;
  top: 0;
  bottom: 0;
  position: absolute;
  left: 0;
  background-color: #FC7F38;
}
.order_details__accordion-item > div:last-child {
  flex-grow: 1;
  color: #9F9F9F;
}
.order_details__accordion-item:first-child > div:first-child::before {
  background-color: #009217;
}
.order_details__accordion-item:first-child > div:last-child {
  color: #000;
}
.order_details__accordion-item:nth-child(2) > div:first-child::before {
  background-color: #009217;
}
.order_details__accordion-item:last-child {
  margin-bottom: 1.25rem;
}
.order_number .number {
  font-weight: 500;
  font-size: 1rem;
  line-height: 100%;
  color: #000;
}
.order_items {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -0.31235rem;
}
.order_items div {
  width: 2.3125rem;
  height: 2.3125rem;
  border-radius: 0.3125rem;
  border: 1px solid #C3C3C3;
  margin: 0 0.3125rem;
  overflow: hidden;
}
.order_items div img {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.order_items div .plus svg {
  width: 0.6875rem;
  height: 0.6875rem;
  stroke: currentColor;
}
.order_address .adress {
  max-width: 14rem;
}
.order_count .count {
  color: #9F9F9F;
}
.order_arrow {
  width: 2rem;
  height: 2rem;
  background-color: #F9F9FF;
  border-radius: 0.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.3125rem;
}
.order_arrow svg {
  width: 0.8125rem;
  height: 0.8125rem;
  stroke: #000;
  transition: 0.3s;
}
.order .separetor {
  margin: 1rem -1rem 0.75rem;
  height: 1px;
  background-color: #DDDDDD;
}
.order.show-details .order_items {
  display: none;
}
.order.show-details .order_count {
  display: none;
}
.order.show-details .order_address {
  padding-left: 2.8125rem;
}
.order.show-details .order_details {
  display: flex;
}
.order.show-details .separetor {
  margin: 0;
  background-color: #fff;
}
.order.show-details .order_arrow svg {
  transform: rotateZ(90deg);
}

.slick-dots {
  margin: 0;
  display: flex;
  justify-content: center;
}
.slick-dots button {
  font-size: 0;
  background-color: #D9D9D9;
  border: none;
  box-shadow: none;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
  margin: 0.3125rem;
  padding: 0;
}
.slick-dots .slick-active button {
  background-color: #000;
}

.prompt {
  width: 100%;
  position: absolute;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0 0 3.125rem rgba(0, 0, 0, 0.1);
  padding: 1rem 1.5rem;
  border-radius: 0.625rem;
  left: 0;
  top: 125%;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
}
.prompt.show {
  opacity: 1;
  pointer-events: unset;
  top: 120%;
}
.prompt .scroll_vertical {
  max-height: 40rem;
}
.prompt_triangle {
  position: relative;
}
.prompt_triangle::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 10px 8px;
  border-color: transparent transparent #ffffff transparent;
  top: -1.7rem;
  left: 3.15rem;
}

.alert {
  border-radius: 0.3125rem;
  padding: 0.3125rem 0.625rem;
  position: relative;
  margin-bottom: 0.75rem;
  min-height: 1.75rem;
}
.alert::before {
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
  top: 1rem;
  left: 1.125rem;
  position: absolute;
}
.alert-danger {
  padding: 0.625rem 0.75rem 0.625rem 2.5rem;
  background-color: #FFF1F1;
}
.alert-danger::before {
  background-color: #FF0000;
}
.alert-success {
  background-color: #EDFFEC;
}
.alert-success svg {
  width: 1.125rem;
  height: 1.125rem;
  fill: #009217;
}

.account_sidebar > a {
  border: 1px solid transparent !important;
}
.account_sidebar > a.active {
  border: 1px solid #0F8CFF !important;
}
.account_sidebar__banner {
  background-image: url(../img/personal_acc/square_orange-bg.jpg);
  background-size: cover;
  padding: 2rem 1.875rem;
  box-shadow: 0 0 3.125rem rgba(0, 0, 0, 0.04);
  border-radius: 0.625rem;
  color: #fff;
}

.sidebar_menu {
  border-radius: 0.625rem;
  box-shadow: 0 0.25rem3 0.125rem rgba(0, 0, 0, 0.04);
  background-color: #fff;
  padding: 0.3125rem;
  overflow: hidden;
  margin-bottom: 1rem;
}
.sidebar_menu__item {
  width: 100%;
  height: 3.125rem;
  color: #000;
  font-weight: 400;
  padding-left: 1.25rem;
  padding-right: 1rem;
  display: none;
  align-items: center;
}
.sidebar_menu__item.active {
  display: flex;
}
.sidebar_menu__item:hover {
  color: #292929;
}
.sidebar_menu__item:last-child {
  border: none;
}
.sidebar_menu.show .sidebar_menu__item {
  border-bottom: 1px solid #F9F9FF;
  display: flex;
  background-color: #fff;
}
.sidebar_menu svg {
  width: 0.625rem;
  height: 0.625rem;
  top: 1.6rem;
  right: 1rem;
}

.promo_copy__svg {
  width: 1.5rem;
  height: 1.5rem;
}

.red_counter {
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 100%;
  font-size: 0.75rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  background-color: #FF0000;
  margin-left: 0.625rem;
}

.radio {
  display: flex;
  align-items: center;
  justify-content: center;
}
.radio input {
  display: none;
}
.radio input:checked + .radio_checker::before {
  border: 1px solid #0F8CFF;
}
.radio input:checked + .radio_checker::after {
  background-color: #0F8CFF;
}
.radio_checker {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}
.radio_checker::before {
  content: "";
  position: absolute;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 100%;
  border: 1px solid #DDDDDD;
}
.radio_checker::after {
  content: "";
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 100%;
  background-color: transparent;
}

.empty_action_slider {
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  margin-right: -1.25rem;
}
.empty_action_slider .product_container__item {
  width: 13.875rem;
  max-width: unset;
}

.promo_copy__svg {
  width: 1.5rem;
  height: 1.5rem;
}

.red_counter {
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 100%;
  font-size: 0.75rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  background-color: #FF0000;
  margin-left: 0.625rem;
}

.radio {
  display: flex;
  align-items: center;
  justify-content: center;
}
.radio input {
  display: none;
}
.radio input:checked + .radio_checker::before {
  border: 1px solid #0F8CFF;
}
.radio input:checked + .radio_checker::after {
  background-color: #0F8CFF;
}
.radio_checker {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}
.radio_checker::before {
  content: "";
  position: absolute;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 100%;
  border: 1px solid #DDDDDD;
}
.radio_checker::after {
  content: "";
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 100%;
  background-color: transparent;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 2.25rem;
}
.pagination_item {
  cursor: pointer;
  background-color: #fff;
  border-radius: 0.25rem;
  border: 1px solid #DDDDDD;
  color: #292929;
  width: 2rem;
  height: 2rem;
  margin: 0 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination_item.active {
  border-color: #4faaff;
  color: #4faaff;
}
.pagination_item.arrow svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: #DDDDDD;
}
.pagination_item:hover {
  background-color: #DDDDDD;
  color: #fff;
}
.pagination_item:hover.arrow svg {
  fill: #fff;
}

.reviews_block + .reviews_block {
  margin-top: 1.5rem;
}

.start_white__accordion .accordion {
  background-color: #fff !important;
}
.start_white__accordion .accordion:nth-child(2n) {
  background-color: #F9F9FF !important;
}

.icon-cross {
  position: relative;
  width: 0.75rem;
  height: 0.75rem;
  background: url(../img/icon-cross.svg);
  background-size: cover;
  display: block;
  margin-left: 0.75rem;
}

.arrow_status {
  position: absolute;
  top: 0;
  right: 0;
  width: 3.125rem;
  height: 3.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}
.arrow_status svg {
  width: 0.875rem;
  height: 0.875rem;
}

.show .arrow_status {
  transform: rotateZ(180deg);
}

.sidebar_menu__item:nth-child(2n) {
  background-color: #F9F9FF;
}

.title_22 {
  font-size: 1.375rem;
}

.w-unset {
  width: unset !important;
}

.pager__item {
  height: 2.5rem !important;
  padding: 0 1rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: unset !important;
  font-size: 1rem !important;
}

.reviews_count {
  padding: 0.25rem 0.5rem;
  border-radius: 0.3125rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  background-color: #FF880E;
}

.breadcrumbs {
  padding: 1rem 0;
  font-size: 0.75rem;
}
.breadcrumbs a {
  padding-left: 0.625rem;
  position: relative;
}
.breadcrumbs a svg {
  width: 0.25rem;
  height: 0.375rem;
  stroke: #0F8CFF;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.breadcrumbs > span {
  color: #767676;
}

.before_footer {
  padding-top: 4.375rem;
  display: flex;
  flex-direction: column;
}
.before_footer__item {
  background-color: #fff;
  padding: 1.25rem 1.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #dddddd;
}
.before_footer__item:last-child {
  border: none;
}
.before_footer__item-img {
  padding-right: 1.125rem;
}
.before_footer__item-img svg {
  width: 3.3125rem;
  height: 3.3125rem;
}
.before_footer__item-desc .title {
  font-size: 1rem;
  font-weight: 500;
  padding-bottom: 0.25rem;
}
.before_footer__item-desc .text {
  font-size: 0.75rem;
  font-weight: 400;
}

.before_footer-lading .before_footer {
  padding-top: 0;
}
.before_footer-lading .before_footer__item {
  background-color: #F9F9FF;
  min-height: 6.875rem;
}

.brands_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0.5rem 1rem 1.2rem;
  background-color: #fff;
}
.brands_item {
  flex: 0 0 5rem;
  padding: 0 0.5rem;
}
.brands_item:last-child {
  flex: 0 0 10rem;
  padding-right: 0;
  flex-grow: 1;
}
.brands_item img {
  width: 100%;
}

.product_border {
  border: 1px solid #ddd;
  border-radius: 0.625rem;
}
.product_header {
  margin-bottom: 2rem;
}
.product_article {
  height: 1.5rem;
  padding: 0 0.375rem;
  background-color: #FF424D;
  color: #fff;
  display: flex;
  align-items: center;
  width: max-content;
  font-size: 0.75rem;
  margin-right: 1rem;
  border-radius: 0.1875rem;
}
.product .reviews {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}
.product_body {
  background-color: #f7f7f7;
}
.product_body .gray {
  color: #9F9F9F;
}
.product_body__left .review_count {
  height: 1.5rem;
  padding: 0 0.5rem;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FF880E;
  border-radius: 0.3125rem;
}
.product_body__right .size span {
  color: #000;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
}
.product_body__right .size svg {
  fill: #0F8CFF;
  width: 1.0625rem;
  height: 1.0625rem;
}
.product_body__right .size_blocks {
  display: grid;
  grid-template-columns: 3rem 3rem 3rem 3rem 3rem 3rem;
  gap: 0.3125rem;
  padding-bottom: 1.875rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #EDEDED;
}
.product_body__right .size_blocks > div, .product_body__right .size_blocks a {
  cursor: pointer;
  background-color: #F8F8FD;
  color: #000;
  border-radius: 0.3125rem;
  padding: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 2.5rem;
  min-width: 3.06rem;
  max-height: 8rem;
  position: relative;
  border: 1px solid transparent;
}
.product_body__right .size_blocks > div.not-available, .product_body__right .size_blocks a.not-available {
  opacity: 0.3;
}
.product_body__right .size_blocks > div.active, .product_body__right .size_blocks a.active {
  background-color: #303030;
  color: #fff;
}
.product_body__right .size_blocks > div.sm_3, .product_body__right .size_blocks a.sm_3 {
  height: 2.8rem;
}
.product_body__right .size_blocks > div svg, .product_body__right .size_blocks a svg {
  opacity: 0;
  position: absolute;
  width: 4rem;
  height: 4rem;
  z-index: 1;
}
.product_body__right .size_blocks > div.sending, .product_body__right .size_blocks a.sending {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
.product_body__right .size_blocks > div.sending span, .product_body__right .size_blocks a.sending span {
  opacity: 0.5;
}
.product_body__right .size_blocks > div.sending svg, .product_body__right .size_blocks a.sending svg {
  opacity: 1;
}
.product_body__right .size_blocks > div.modification__button--stockout, .product_body__right .size_blocks a.modification__button--stockout {
  opacity: 0.7;
  border: 1px solid transparent;
}
.product_body__right .size_blocks > div.modification__button--active, .product_body__right .size_blocks a.modification__button--active {
  border: 1px solid #0F8CFF;
}
.product_body__right .size_blocks > div span, .product_body__right .size_blocks a span {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.25rem;
}
.product_body__right .bay_block {
  display: flex;
  margin: 0 -0.4375rem;
  padding-bottom: 1rem;
}
.product_body__right .bay_block.last {
  border-bottom: 1px solid #EDEDED;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}
.product_body__right .bay_block_item {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 0.4375rem;
}
.product_body__right .bay_block_item .available {
  font-size: 0.625rem;
  font-weight: 500;
  color: #000;
}
.product_body__right .bay_block_item .available svg {
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.1875rem;
  fill: #009217;
}
.product_body__right .bay_block_item .not-available {
  font-size: 0.625rem;
  font-weight: 500;
  color: #B7B7BA;
}
.product_body__right .bay_block_item .not-available svg {
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.1875rem;
  fill: #B7B7BA;
}
.product_body__right .bay_block_count {
  background-color: #F9F9FF;
  border-radius: 0.25rem;
  padding: 0.5rem 0;
  height: 2.25rem;
}
.product_body__right .bay_block_count .count_symbol {
  font-size: 1rem;
  color: #000;
  font-weight: 400;
  padding: 0 0.25rem 0 0.75rem;
  cursor: pointer;
}
.product_body__right .bay_block_count .count_symbol.left {
  padding: 0 0.75rem 0 0.25rem;
}
.product_body__right .bay_block_count input {
  width: 1.5rem;
  border: none;
  background-color: transparent;
  text-align: center;
}
.product_container {
  margin: 0 2.625rem;
}
.product_container.small {
  margin: 0 -0.1875rem;
  display: flex;
  overflow: auto;
  padding-bottom: 1.25rem;
}
.product_container.small .product_container__wrapper {
  padding: 0 0.1875rem;
}
.product_container.small .product_container__item {
  border: 1px solid #ddd;
  width: 8.75rem;
}
.product_container.small .product_container__item-img {
  width: 100%;
  height: 6.93rem;
}
.product_container.small .product_container__item-info {
  padding: 0.625rem 0.75rem 0.25rem 0.75rem;
}
.product_container.small .product_container__item-info .desc {
  margin-bottom: 0.5rem;
}
.product_container.small .product_container__footer .sum {
  font-weight: 700;
  display: flex;
  align-items: flex-end;
  position: relative;
}
.product_container.small .product_container__footer .sum .discount {
  padding: 0.125rem;
  color: #fff;
  background-color: #FC7F38;
  font-size: 0.5rem;
  line-height: 0.5625rem;
  font-weight: 500;
  left: 0;
  top: -0.125rem;
  position: absolute;
}
.product_container.small .product_container__footer .cart {
  width: 1.875rem;
  height: 1.75rem;
}
.product_container.small .product_container__footer .cart svg {
  width: 1rem;
  height: 1rem;
}
.product_container__wrapper {
  padding: 0 0.625rem;
}
.product_container__wrapper.product_sm {
  padding: 0 0.1875rem 0.375rem 0.1875rem;
}
.product_container__wrapper.product_sm .product_container__item {
  width: 100%;
  max-width: 13.875rem;
  box-shadow: 0 0 3.125rem rgba(0, 0, 0, 0.04);
}
.product_container__wrapper.product_sm .product_container__item-img {
  width: 100%;
  height: 6.9375rem;
}
.product_container__wrapper.product_sm .product_container__item-info {
  padding: 0 0.75rem 0.75rem;
}
.product_container__wrapper.product_sm .product_container__item-info .desc {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.9rem;
  margin-bottom: 1.125rem;
}
.product_container__wrapper.product_sm .product_container__footer {
  display: flex;
  justify-content: space-between;
}
.product_container__wrapper.product_sm .product_container__footer .sum {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
}
.product_container__wrapper.product_sm .product_container__footer .cart {
  background-color: #0F8CFF;
  width: 2.125rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
}
.product_container__wrapper.product_sm .product_container__footer .cart svg {
  fill: #fff;
  width: 1.125rem;
  height: 1.125rem;
}
.product_container__item {
  background-color: #fff;
  border-radius: 0.625rem;
  overflow: hidden;
  width: 100%;
  max-width: 13.875rem;
  position: relative;
}
.product_container__item .product_status {
  padding: 0.25rem 0.75rem;
  height: 1.5rem;
  border-radius: 1.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333333;
  position: absolute;
  top: 1.125rem;
  left: 1.125rem;
  z-index: 10;
}
.product_container__item .product_status.action {
  background-color: #FC7F38;
}
.product_container__item .product_status.sale {
  background-color: #009217;
}
.product_container__item-img {
  width: 100%;
  height: 9.625rem;
}
.product_container__item-img img {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.product_container__item-info {
  padding: 0 0.75rem 0.75rem;
}
.product_container__item-info .desc {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.9rem;
  margin-bottom: 1.125rem;
}
.product_container__footer {
  display: flex;
  justify-content: space-between;
}
.product_container__footer .sum {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
}
.product_container__footer .cart {
  background-color: #0F8CFF;
  width: 2.125rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
}
.product_container__footer .cart svg {
  fill: #fff;
  width: 1.125rem;
  height: 1.125rem;
}
.product_size__grid-title {
  display: flex;
  height: 2.125rem;
  padding: 0 1.125rem;
}
.product_size__grid-line {
  display: flex;
  height: 2.125rem;
  padding: 0 1.125rem;
}
.product_size__grid-line:nth-child(2n) {
  background-color: #F7F7FC;
  border-radius: 0.25rem;
}
.product_size__grid .item {
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}
.product_size__grid .item:first-child {
  font-weight: 600;
}
.product .gray_block {
  background-color: #F7F7FC;
  padding: 1.25rem;
  border-radius: 0.25rem;
}
.product .modification__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 0 1rem 0;
}
.product .modification__item {
  position: relative;
  margin: 0;
  display: block;
  border: 0;
  padding: 1px !important;
  height: 3rem;
}
.product .modification__item--active .modification__color, .product .modification__item--active .modification__preview {
  cursor: default;
}
.product .modification__color {
  display: block;
  position: absolute !important;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: unset !important;
  height: unset !important;
  box-shadow: unset !important;
}
.product .modification__color, .product .modification__preview {
  border-radius: 0.3125rem;
  position: relative;
  background-size: cover !important;
}
.product .modification__item--active .modification__color:before, .product .modification__item--active .modification__preview:before {
  content: "";
  position: absolute;
  border: 1px solid #0466b1;
  border-radius: 0.3125rem;
  width: calc(100% - 2px);
  top: 0;
  bottom: 0;
}

.checkout {
  padding: 1.25rem 1rem;
  background-color: #F9F9FF;
}
.checkout .basket_item {
  box-shadow: none;
  border: 1px solid #DDDDDD;
}
.checkout .change-order-btn {
  top: 50%;
  right: 1.5rem;
  display: none;
}
.checkout .card {
  border-radius: 0.625rem;
  padding: 1rem;
}
.checkout .card_title {
  padding-bottom: 1rem;
}
.checkout .card.error {
  border: 1px solid #FF0000 !important;
}
.checkout .card.error .wrapper_field span {
  color: #FF0000;
}
.checkout .wrapper_field {
  display: none;
  margin: 0 -0.625rem;
}
.checkout .wrapper_field span {
  padding: 0 0.625rem;
  position: relative;
}
.checkout .basket_item__wrapper {
  flex-direction: column;
}
.checkout .basket_modal__item {
  height: 7.25rem;
  border: 1px solid #DDDDDD;
  border-radius: 0.46875rem;
}
.checkout .basket_modal__item-img {
  flex: 0 0 4.75rem;
  max-width: 4.75rem;
}
.checkout .not-active {
  box-shadow: none;
}
.checkout .not-active .basket_item__wrapper {
  margin: 0 -0.375rem;
}
.checkout .not-active .basket_modal__item {
  flex: 0 0 4.875rem;
  max-width: 4.875rem;
  height: 5.3125rem;
  border: none;
  padding-right: 0;
  margin: 0 0.375rem 0.625rem;
}
.checkout .not-active .basket_modal__item-content {
  display: none !important;
}
.checkout .not-active .basket_modal__item-img {
  flex: 0 0 4.75rem;
  max-width: 4.75rem;
}
.checkout .not-active .basket_modal__item svg {
  display: none;
}
.checkout .not-active .btn-primary {
  display: none;
}
.checkout .not-active .btn-primary-outline {
  display: flex !important;
}
.checkout .not-active .get_in, .checkout .not-active .wrapper_data {
  display: none;
}
.checkout .not-active .wrapper_field {
  display: flex;
  flex-direction: column;
}
.checkout .not-active.card {
  background-color: #F0F0FE !important;
  box-shadow: 0 0 3.125rem rgba(0, 0, 0, 0.04);
}
.checkout .not-active.card .card_body {
  display: flex;
  padding: 1rem 0 0 2.9rem;
  flex-direction: column;
}
.checkout .not-active.card .card_title {
  padding: 0;
}
.checkout .not-active.card .card_title span {
  background-color: #fff;
}
.checkout .not-active .widget {
  height: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
}
.checkout .not-active .basket_item {
  width: unset;
  height: unset;
  padding-right: 0;
  margin-bottom: 0.75rem;
  margin-right: 0.75rem;
  border: none;
  border-radius: 0.46875rem;
  overflow: hidden;
}
.checkout .not-active .basket_item__wrapper {
  flex-direction: row;
}
.checkout .not-active .basket_item-img {
  flex: 0 0 4.875rem;
  max-width: 4.875rem;
  height: 5.3125rem;
}
.checkout .not-active .basket_item img {
  object-fit: cover;
  width: 100%;
  min-height: 100%;
}
.checkout .not-active .basket_item-text, .checkout .not-active .basket_item-counter, .checkout .not-active .basket_item-price, .checkout .not-active .basket_item svg {
  display: none;
}
.checkout .address_fields {
  margin: 0 -0.375rem;
  flex-wrap: wrap;
}
.checkout .address_fields > div {
  padding: 0 0.375rem;
  flex: 0 0 33.33%;
  max-width: 33.33%;
}
.checkout .address_fields > div:first-child {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 0.5rem;
}
.checkout .address_fields > div input {
  padding: 0.5rem;
}
.checkout .description_pay-type {
  width: 100%;
  padding: 1rem 1.25rem;
  border-radius: 0.625rem;
  background-color: #F7F7FC;
}

.search_container {
  padding: 0 1.25rem;
}
.search_noresults-img {
  width: 7.845rem;
}
.search_noresults-img img {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
}
.search_noresults-info li {
  padding-left: 1.25rem;
  margin-bottom: 0.75rem;
  position: relative;
}
.search_noresults-info li::before {
  content: "";
  position: absolute;
  width: 0.3125rem;
  height: 0.3125rem;
  border-radius: 50%;
  background: #0f8cff;
  left: 0;
  top: 0.375rem;
}
.search_noresults-info li:last-child {
  margin-bottom: 2rem;
}
.search_noresult-possible {
  max-width: 43.375rem;
}
.search_noresult-possible-title {
  font-size: 0.8125rem !important;
  margin: 0 0 0.625rem 0;
  color: #707070;
}
.search_noresult-possible a {
  border-bottom: 0.0875rem dashed #000000;
}
.search_noresult-btn {
  margin: 1.875rem -0.5rem 0 -0.5rem;
  max-width: 36.625rem;
}
.search_noresult-btn--item {
  padding: 0 0.5rem;
}
.search .recommendation-title {
  margin: 4.375rem 0 1.875rem 0;
}
.search-tab-nav {
  padding-bottom: 1.875rem;
}
.search-tab-nav ul li {
  display: inline-block;
}
.search-tab-nav ul li.ui-tabs-active.ui-state-active a {
  color: #ffffff;
  background-color: #303030;
  margin: 0.25rem;
  box-shadow: 0 0 0.75rem 0 rgba(130, 130, 130, 0.66);
}
.search-tab-nav ul li.ui-state-hover a {
  background-color: #DDDDDD;
  color: #000000;
  margin: 0.25rem;
  box-shadow: 0 0 0.75rem 0 rgba(130, 130, 130, 0.66);
}
.search-tab-nav ul li a {
  display: block;
  padding: 0.625rem 1.75rem;
  background-color: #ffffff;
  border-radius: 0.3125rem;
  transition: 0.3s;
  width: max-content;
  margin: 0.25rem 1rem 0.25rem 0.25rem;
  box-shadow: 0 0 0.75rem 0 rgba(130, 130, 130, 0.66);
}
.search-tab-nav ul li a.active-link {
  color: #ffffff;
  background-color: #303030;
}
.search-tab-content .tab-element {
  display: none;
}
.search-tab-content .active {
  display: block;
}
.search-tab-content .widget {
  padding: 0;
}
.search-tab-content .widget .product_container {
  margin-left: 0;
}
.search .product_container__item {
  max-width: unset;
}
.search .product_container__item-img {
  height: 12rem;
}

.thank {
  padding-bottom: 2rem;
}
.thank_img {
  width: 14.125rem;
}
.thank_copy {
  width: 1.5rem;
  height: 1.5rem;
}
.thank .rating_wrapper {
  display: flex;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.thank .rating_wrapper .checkbox {
  align-items: center !important;
}
.thank .rating_wrapper > div {
  padding: 0 0.5rem 1rem;
}
.thank .rating_wrapper__lg {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.thank .rating_wrapper__lg > div {
  padding: 0 0.75rem;
  margin-bottom: 1.5rem;
  width: 100%;
}
.thank .voice img {
  width: 9rem;
}

.landing {
  padding-top: 1.25rem;
}
.landing .banner.banner_lg {
  padding: 1.375rem 1.25rem;
}
.landing .container {
  padding: 0 1.25rem;
}
.landing .color_card {
  height: 11rem;
  padding-top: 1rem;
}
.landing_menu {
  background-color: #fff;
  border: 0.3125rem solid #fff;
  box-shadow: 0 0 3.125rem rgba(0, 0, 0, 0.04);
  border-radius: 0.625rem;
}
.landing_menu__item {
  background-color: #F9F9FF;
  height: 2.625rem;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.landing_menu__item img {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.75rem;
}
.landing_menu__item:nth-child(2n) {
  background-color: #fff;
}
.landing_menu__item svg {
  width: 0.4375rem;
}
.landing_menu__item:hover .landing_submenu {
  opacity: 1;
  pointer-events: unset;
  margin-left: 0;
}
.landing_submenu {
  position: absolute;
  width: 14rem;
  padding: 1rem 1.5rem;
  background-color: #fff;
  border-radius: 0 10px 10px 0;
  top: -0.3125rem;
  left: 100%;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  margin-left: -30px;
  transition: 0.3s;
  box-shadow: 25px 25px 30px rgba(0, 0, 0, 0.1);
}
.landing_submenu > a {
  display: block;
  font-size: 0.875rem;
  padding: 0.5rem 0;
}
.landing .categories {
  padding-top: 1.25rem;
  margin: 0 -0.4375rem;
  display: flex;
  flex-wrap: wrap;
}
.landing .categories_item {
  padding: 0 0.4375rem;
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
}
.landing .categories_item .category_card {
  flex-grow: 1;
  background: #fff;
  border-radius: 0.625rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.875rem;
  min-height: 9.5625rem;
}
.landing .categories_item .category_card__img {
  width: 100%;
  height: 4.625rem;
  display: flex;
  justify-content: center;
}
.landing .categories_item .category_card__img img {
  width: 100%;
  min-height: 100%;
  object-fit: contain;
}
.landing .categories_item .category_card__title {
  font-weight: 500;
  font-size: 0.875rem;
  padding-top: 0.5rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.landing .categories_item .category_card__links {
  flex-grow: 1;
}
.landing .categories_item .category_card__links a {
  display: block;
  padding: 0.625rem;
  font-size: 0.875rem;
  background-color: #F7F7FD;
  border-radius: 0.3125rem;
}
.landing .categories_item .category_card__links a:nth-child(2n) {
  background-color: #fff;
}
.landing .categories_item .category_card__footer {
  padding: 0.625rem;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: center;
}
.landing .last_order {
  padding-top: 2.1875rem;
}
.landing .last_order__wrapper {
  display: flex;
  margin: 0 -0.8125rem;
  flex-wrap: wrap;
}
.landing .last_order__left {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 0.8125rem;
}
.landing .last_order__right {
  flex: 0 0 19.125rem;
  max-width: 19.125rem;
  padding: 0 0.8125rem;
}
.landing .cart {
  padding-top: 2.1875rem;
  margin-top: 2.5rem;
  padding-bottom: 2.5rem;
  background-color: #fff;
}
.landing .cart .product_container__item {
  width: 10.55rem;
}
.landing .cart .product_container__item-info {
  padding-left: 0.68rem;
  padding-right: 0.68rem;
  padding-bottom: 1.125rem;
}
.landing .landing_cart__slide-wrap {
  padding: 0 3.75rem;
}
.landing .promo {
  padding: 2.8rem 0;
}
.landing .promo .color_card {
  padding: 1.375rem 1rem 1.375rem 1.875rem;
}
.landing .promo_title {
  font-size: 2.25rem;
  line-height: 2.625rem;
}
.landing .promo_text {
  width: 13.125rem;
  font-size: 0.875rem;
  margin-bottom: 1.3rem;
}
.landing .promo svg {
  width: 1.5rem;
  height: 1.5rem;
}
.landing .action_slider__wrapper {
  padding: 0 2.8rem;
}
.landing .action_slider__wrapper .product_container__item {
  max-width: unset;
}
.landing .articles {
  padding: 2.5rem 0;
}
.landing .articles_slider {
  margin: 0 -0.625rem;
  display: flex;
}
.landing .articles_slider__item {
  width: 14.125rem;
  padding: 0 0.625rem;
  line-height: 1.25rem;
  font-weight: 500;
  margin-bottom: 1.25rem;
}
.landing .faq {
  padding: 2.5rem 0;
  background-color: #fff;
}
.landing .faq .accordion_item {
  padding: 1.4rem 1.25rem;
}
.landing .accordion_item__title {
  font-size: 1rem;
}
.landing_page .container {
  padding: 0 1rem;
}
.landing_page section {
  padding: 3.125rem 0;
}
.landing_page h2 {
  margin-bottom: 1.6875rem;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
}
.landing_page .banner {
  color: #fff;
  background-image: url(../img/landing_page/banner.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.875rem;
  box-shadow: 0 0 8rem rgba(0, 0, 0, 0.04);
  padding: 16.75rem 1.125rem 2.5rem;
  height: 33.375rem;
  margin-bottom: 6.875rem;
}
.landing_page .banner_wrapper {
  max-width: 47rem;
}
.landing_page .banner_bread-crumbs {
  margin-bottom: 1.125rem;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  line-height: 1.5625rem;
}
.landing_page .banner_title {
  font-size: 1.875rem;
  line-height: 2rem;
  margin-bottom: 1.125rem;
  font-weight: 500;
  text-align: center;
}
.landing_page .banner_price {
  border-radius: 6.25rem;
  border: 1px solid #fff;
  margin-bottom: 1.125rem;
  display: flex;
  width: max-content;
}
.landing_page .banner_price > * {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
}
.landing_page .banner_price-left {
  padding: 0.625rem 0.75rem;
  border-right: 1px solid #fff;
}
.landing_page .banner_price-right {
  padding: 0.625rem 0.75rem;
}
.landing_page .banner_btn {
  height: 3.125rem;
  width: 100%;
  font-weight: 500;
  font-size: 1.125rem;
}
.landing_page .advantages_list-item {
  margin-bottom: 1.25rem;
  display: flex;
  align-items: flex-start;
}
.landing_page .advantages_list-item svg {
  flex: 0 0 1.5rem;
  max-width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.625rem;
  fill: #0F8CFF;
}
.landing_page .advantages_list-item .text {
  font-weight: 400;
  font-size: 1rem;
}
.landing_page .personal_items {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 -0.4375rem 0.4375rem;
  flex-wrap: wrap;
}
.landing_page .personal_items-item {
  padding: 0 0.4375rem;
  margin-bottom: 0.75rem;
}
.landing_page .personal_items-item .item_wrapper {
  background-color: #fff;
  border-radius: 0.875rem;
  padding: 1rem 1.5rem 0.85rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 9.82rem;
  height: 9.82rem;
}
.landing_page .personal_items-item .item_wrapper img {
  width: 5.875rem;
  height: 5.875rem;
  margin-bottom: 0.75rem;
}
.landing_page .personal_items-item .text {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
}
.landing_page .structure_container {
  position: relative;
  text-align: center;
}
.landing_page .structure_container img {
  width: 17.5rem;
  margin: 0 auto;
}
.landing_page .structure_white-block {
  background-color: #fff;
  padding: 0.9375rem 0.75rem 0.9375rem 0.9375rem;
  border-radius: 0.625rem;
  box-shadow: 0 0.25rem 3.125rem rgba(0, 0, 0, 0.04);
  height: max-content;
  text-align: left;
  font-size: 0.875rem;
}
.landing_page .structure_white-block .title {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.625rem;
}
.landing_page .structure_white-block.top {
  margin-left: auto;
  width: 15.4375rem;
}
.landing_page .cost {
  background-color: #fff;
}
.landing_page .cost .col-4, .landing_page .cost .col-8 {
  padding: 0 0.25rem;
}
.landing_page .cost_wrapper .active {
  color: #fff;
  background-color: #0F8CFF;
}
.landing_page .cost_color__block {
  background-color: #F9F9FF;
  border-radius: 0.625rem;
  overflow: hidden;
}
.landing_page .cost_color__block-item {
  padding: 1.875rem 1.125rem;
  font-size: 1.25rem;
  font-weight: 700;
  width: max-content;
}
.landing_page .cost_color__block-item.before {
  position: relative;
}
.landing_page .cost_color__block-item.before::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 5.3125rem;
  height: 1px;
  background-color: #fff;
  left: 50%;
  transform: translateX(-50%);
}
.landing_page .cost_color__block-item.before.gray::before {
  background-color: #C6C6C6;
}
.landing_page .etaps_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.landing_page .etaps_container {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}
.landing_page .etaps_item {
  background-color: #fff;
  padding: 4.25rem 1.8rem 1.6875rem 1.5rem;
  border-radius: 0.625rem;
  position: relative;
}
.landing_page .etaps_item::before {
  counter-increment: section;
  content: counter(section);
  position: absolute;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 100%;
  background-color: #F9F9FF;
  color: #0F8CFF;
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 1.5rem;
  left: 1.5rem;
}
.landing_page .etaps_item__title {
  font-weight: 600;
  font-size: 1.125rem;
  margin-bottom: 1.25rem;
}
.landing_page .reviews {
  background-color: #fff;
}
.landing_page .reviews_slider-landing {
  display: flex;
  width: 100%;
  overflow: auto;
}
.landing_page .reviews_slider-landing .gray_block {
  width: 16.875rem;
}

.account_sidebar__banner {
  background-image: url(../img/personal_acc/square_orange-bg.jpg);
  background-size: cover;
  padding: 2rem 1.875rem;
  box-shadow: 0 0 3.125rem rgba(0, 0, 0, 0.04);
  border-radius: 0.625rem;
  color: #fff;
}
.account_data.empty .card_body {
  padding-top: 1.25rem;
}
.account_data.empty .empty_box {
  width: 13.8125rem;
  height: 13.8125rem;
  margin-bottom: 1rem;
}
.account_data.empty .empty_title {
  font-size: 1.625rem;
  font-weight: 500;
  margin-bottom: 1rem;
}
.account_data.empty .empty_subtitle {
  font-size: 1rem;
  margin-bottom: 1rem;
  font-weight: 400;
}

#newReviewAccount.modal .basket_item-img {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.account .rewievs_block {
  padding: 1.875rem;
}
.account_data input {
  height: 2.8125rem;
  border-radius: 0.3125rem;
  border: 1px solid #DDDDDD;
  padding-left: 0.875rem;
  font-size: 1rem;
}
.account_data .title_26 {
  margin-bottom: 3.44rem;
}
.account_data .personal_data input {
  display: none;
}
.account_data .personal_data.enabled input {
  display: block;
}
.account_data .personal_data.enabled span {
  display: none;
}
.account_data .personal_data.enabled .edit {
  display: none !important;
}
.account_data .personal_data.enabled .data_btn__events {
  display: flex !important;
}
.account_data .delivery_address .address-editing {
  display: none;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
.account_data .delivery_address .address-editing-wrapper > div input {
  width: 100%;
}
.account_data .delivery_address .enabled .address-editing {
  display: flex;
}
.account_data .delivery_address .enabled .address-editing .address_actions {
  padding-top: 1.25rem;
}
.account_data .delivery_address .enabled .address-saved {
  display: none;
}
.account_data .address_actions {
  margin: 0 -0.75rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.account_data .address_actions > div {
  padding: 0 0.75rem;
}
.account_data .address_actions .btn_square {
  height: 2.25rem;
  width: 2.25rem;
  background-color: #F9F9FF;
  padding: 0;
}
.account_data .address_actions .btn_square svg {
  width: 1.25rem;
  height: 1.25rem;
  stroke: #000;
  fill: none;
}
.filter svg {
  width: 0.625rem;
  height: 1rem;
  margin-right: 0.625rem;
}

.my_orders .search-tab-nav, .showed .search-tab-nav {
  justify-content: flex-start;
}
.my_orders .search-tab-nav ul, .showed .search-tab-nav ul {
  display: flex;
}
.my_orders .search-tab-nav ul li a, .showed .search-tab-nav ul li a {
  margin-right: 0.25rem;
  box-shadow: unset;
}
.my_orders .search-tab-nav ul li a.active-link, .showed .search-tab-nav ul li a.active-link {
  background-color: #fff;
  color: #333;
  border: 1px solid #0F8CFF;
}

.my_reviews .reviews_block {
  padding: 1.25rem;
}
.my_reviews .reviews_block + .reviews_block {
  margin-top: 1.5rem;
}
.my_reviews .reviews_block__img {
  padding: 0.5rem;
  background-color: #fff;
  border-radius: 0.3125rem;
  width: 11.56rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}
.my_reviews .reviews_block__img-img {
  flex: 0 0 3.25rem;
  max-width: 3.25rem;
  height: 3.25rem;
}
.my_reviews .reviews_block__img-img img {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
}
.my_reviews .reviews_block__img-text {
  font-size: 0.75rem;
  font-weight: 400;
}
.my_reviews .reviews_block__edit span:last-child {
  display: none;
}
.my_reviews .reviews_block__edit.editing span:first-child {
  display: none;
}
.my_reviews .reviews_block__edit.editing span:last-child {
  display: block;
}
.my_reviews .reviews_block .btn.btn_sm {
  height: 2rem;
  padding: 0 1.125rem;
  font-size: 0.875rem;
  flex: 0 0 50%;
  max-width: 50%;
}
.my_reviews .categories {
  flex-wrap: nowrap !important;
  padding-bottom: 0.5rem;
}
.my_reviews .categories_item {
  flex: 0 0 13.875rem;
  max-width: 13.875rem;
}
.my_reviews .categories_item .category_card {
  height: unset !important;
}
.my_reviews .categories_item .category_card__img {
  width: 9.625rem !important;
  height: 9.625rem !important;
}
.my_reviews .categories_item .category_card__title {
  text-align: left !important;
}

.error .product_container__item {
  min-height: 21.5rem;
  max-width: unset;
  min-width: 15rem;
  margin-bottom: 1rem;
}
.error-wrapper {
  margin-bottom: 4.375rem;
}
.error_img {
  width: 17.18rem;
  height: 11.4375rem;
  margin-bottom: 2rem;
}

.contact_list {
  flex: 0 0 24.2rem !important;
  max-width: 24.2rem !important;
}
.contact_list__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.58rem;
}
.contact_list__item:last-child {
  margin-bottom: 0;
}
.contact_list__item svg {
  flex: 0 0 1rem;
  max-width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}
.contact .feedback .form-group {
  padding-bottom: 0;
  margin-bottom: 1.25rem;
}
.contact .branch {
  padding: 0;
}
.contact .branch + .branch {
  margin-top: 1.25rem;
}
.contact .branch_img {
  width: 100%;
  height: 14.875rem;
}
.contact .branch_img img {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
}
.contact .branch_title {
  padding: 2.1875rem 0;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 500;
}
.contact .shop_region__list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -0.4rem 2.8rem;
}
.contact .shop_region__list-item {
  width: 10.3rem;
  padding: 0 0.4rem;
  margin-bottom: 0.8rem;
}
.contact .shop_region__list-item--wrapper {
  padding: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact .shop_region__list-item--img {
  width: 7.625rem;
}
.contact .shop_region__list-item--img img {
  width: 100%;
  min-height: 100%;
  object-fit: contain;
}
.contact .shop_region__list-item--title {
  padding-top: 0.75rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.catalog {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
}
.catalog_sidebar {
  padding-bottom: 0.5rem;
}
.catalog_content {
  padding-top: 0;
}
.catalog_content .articles_slider__item {
  width: 100%;
}
.catalog .articles_slider__item {
  margin-bottom: 1.25rem;
  display: flex;
  flex-direction: column;
}
.catalog .articles_slider__item .article {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.catalog .articles_slider__item .article_title {
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 1.5rem 2rem;
  flex-grow: 1;
  font-size: 1.125rem;
}
.catalog .articles_slider__item .article_img {
  height: 13.32rem;
}
.catalog .articles_slider__item .article_img img {
  min-height: 100%;
}

.article_page__content {
  padding: 0 0.75rem;
}
.article_page__content p {
  font-size: 1rem;
}
.article_page .articles {
  padding-top: 2.8rem;
}
.article_page .articles_slider__item {
  padding: 0 0.4375rem;
  width: 13.5rem;
  margin-bottom: 1.25rem;
}

.prod_theme .product_container__item {
  max-width: unset;
  width: 14.125rem;
  margin-bottom: 1rem;
}

.store_list {
  margin-bottom: 2.1875rem;
}
.store_list__item {
  box-shadow: 0 0.25rem 3.125rem rgba(0, 0, 0, 0.04);
  border-radius: 0.625rem;
  background-color: #fff;
  overflow: hidden;
  margin-bottom: 1.25rem;
}
.store_list__item:last-child {
  margin-bottom: 0;
}
.store_list__item-img {
  width: 100%;
  height: 13.8125rem;
}
.store_list__item-img img {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.store_list__item-text {
  padding: 1.25rem;
}
.store_list__item-text--item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 0.625rem;
}
.store_list__item-text--item:last-child {
  margin-bottom: 0;
}
.store_list__item-text--item svg {
  flex: 0 0 1.5rem;
  max-width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}
.store_list__item-text--item > div {
  padding-top: 1px;
}

.all_reviews {
  padding-bottom: 2.8125rem;
}
.all_reviews .reviews_count {
  padding: 0.25rem 0.5rem;
  border-radius: 0.3125rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  background-color: #FF880E;
}
.all_reviews .gray_block {
  margin-bottom: 1rem;
  padding: 1.25rem;
}

.reviews_form {
  width: 100%;
  padding: 2.8125rem 1.25rem 1.25rem;
  border-radius: 0.625rem;
  overflow: hidden;
  margin-bottom: 1.5rem;
}
.reviews_form__wrapper {
  flex: 0 0 21.875rem;
  max-width: 21.875rem;
}
.reviews_form__wrapper .form-group {
  padding-top: 0.625rem;
  padding-bottom: 0;
}
.reviews_form__wrapper .form-group:first-child {
  padding: 0;
}
.reviews_form .card_title {
  padding: 1.5rem 1.5rem 0;
}
.reviews_form .card_body {
  padding: 1.5rem;
}

.products_list {
  margin: 0 -0.4375rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.products_list__item {
  height: 11.125rem;
  background: #fff;
  box-shadow: 0 0.25rem 3.125rem rgba(0, 0, 0, 0.04);
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75rem 1rem 1.625rem;
}
.products_list__item-wrapper {
  padding: 0 0.4375rem;
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 1.125rem;
}
.products_list__item-img {
  flex: 0 0 6.125rem;
  max-width: 6.125rem;
  height: 6.125rem;
}
.products_list__item-img img {
  width: 100%;
  min-height: 100%;
}
.products_list__item-text {
  text-align: center;
  font-weight: 500;
  font-size: 0.875rem;
  color: #000;
}

.mm-blocker {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
  opacity: 0;
  transition: 0.3s;
}
.mm-blocker.show {
  opacity: 1;
}

._filter {
  position: relative;
}

.filter {
  padding: 1rem 1.25rem;
}
.filter-item {
  padding: 0.75rem;
  border-radius: 0.3125rem;
}
.filter-item:nth-child(2n) {
  background-color: #F9F9FF;
}
.filter-item + .filter-item {
  margin-top: 0.5rem;
}
.filter-item__title {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
}
.filter-item__title + * {
  margin-top: 0.75rem;
}
.filter-item__link {
  display: flex;
}
.filter-item__link .filter-item__checkbox {
  display: block;
  border: 1px solid #DDDDDD;
  border-radius: 0.1875rem;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.625rem;
  position: relative;
}
.filter-item input[type=checkbox] {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.filter-item input[type=checkbox]:checked + label .filter-item__checkbox {
  background-color: #0F8CFF;
  border-color: #0F8CFF;
}
.filter-item input[type=checkbox]:checked + label .filter-item__checkbox i {
  position: absolute;
  left: 50%;
  top: 42%;
  transform: translate(-50%, -50%) rotateZ(-45deg);
  width: 0.75rem;
  height: 0.35rem;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.filter-item .range-select__inputs {
  display: flex;
  margin: 0 -0.25rem;
}
.filter-item .range-select__input {
  padding: 0 0.25rem;
}
.filter-item .range-select__input .input {
  border: 1px solid #DDDDDD;
  border-radius: 0.25rem;
  width: 5rem;
  height: 2rem;
}
.filter-item .range-select__input .btn {
  border: none;
  font-size: 0.875rem;
  color: #fff;
  width: 2.5rem;
  height: 2rem;
  border-radius: 0.1875rem;
  background-color: #0F8CFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: 0.25rem;
}
.filter-item .range-select__separator {
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter-item .range-select__separator::before {
  content: "-";
}

.catalog-settings {
  top: 0;
  position: fixed;
  width: 18.2rem;
  background-color: #fff;
  height: 100%;
  z-index: 1;
  left: -100%;
  transition: 0.3s;
}
.catalog-settings.mm-opened {
  left: 0;
}
.catalog-settings__block {
  position: static !important;
  width: unset;
}
.catalog-settings .mm-panel + .mm-panel {
  height: 100%;
}
.catalog-settings .mm-panel + .mm-panel .mm-subnav {
  height: 3.375rem;
  background-color: #F7F7FC;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.catalog-settings .mm-panel + .mm-panel .mm-subnav .mm-btn::before {
  content: "";
  width: 0.625rem;
  height: 0.625rem;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
  transform: rotateZ(-45deg) translateY(-50%);
  position: absolute;
  left: 1.75rem;
  top: 50%;
}
.catalog-settings .mm-panel + .mm-panel .mm-subnav .mm-title {
  font-weight: 600;
  color: #000;
}
.catalog-settings .mm-panel:last-child {
  padding-bottom: 0;
}
.catalog-settings .btn-toolbar {
  display: flex;
  padding: 1rem 1.25rem;
}
.catalog-settings .btn-toolbar button + button {
  margin-left: 0.625rem;
}
.catalog-settings__header {
  height: 3.375rem;
  background-color: #F7F7FC;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.catalog-settings__title {
  font-weight: 600;
  color: #000;
}
.catalog-settings__close {
  position: absolute;
  left: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
}
.catalog-settings__close button {
  background-color: transparent;
  border: none;
  padding: 0;
}
.catalog-settings__close .icon--menu-hide {
  width: 1.5rem;
  height: 1.5rem;
}
.catalog-settings__content .heading {
  display: none;
}

.mm-hasnavbar {
  top: 0;
  position: fixed;
  width: 18.2rem;
  background-color: #fff;
  z-index: 2;
  transition: 0.3s;
  left: -100%;
}
.mm-hasnavbar.mm-opened {
  left: 0;
}
.get_filters {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2.1875rem;
}
.get_filters__item {
  height: 1.25rem;
  background-color: #0F8CFF;
  margin-right: 0.625rem;
  margin-bottom: 0.625rem;
  padding: 0 0.375rem;
  color: #fff;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  white-space: nowrap;
}
.get_filters__item svg {
  width: 0.75rem;
  height: 0.75rem;
  margin-left: 0.625rem;
}
.get_filters__item:last-child {
  margin: 0;
}

.gray_block {
  padding: 1rem 1.25rem;
  max-width: 16.8rem;
  margin-right: 1rem;
  margin-bottom: 1.5rem;
}
.gray_block:last-child {
  margin-right: 0;
}
.gray_block .reviews_block__img {
  width: 11.56rem;
  height: 4.06rem;
  background-color: #fff;
  border-radius: 0.3125rem;
  margin: 0.625rem 0 0.625rem;
}
.gray_block .reviews_block__img-text {
  padding: 0.3125rem 0.625rem 0.75rem 0.875rem;
  font-size: 0.75rem;
}
.gray_block .reviews_block__img-img {
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 0.3125rem;
}
.gray_block .reviews_block__img-img img {
  object-fit: contain;
  max-height: 100%;
}

.filter-current-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.filter-current-heading {
  padding-right: 0.5rem;
  margin-bottom: 0.3rem;
}
.filter-current-heading i {
  display: none;
}
.filter-current-i {
  padding: 0.125rem 0.375rem;
  border-radius: 0.25rem;
  background-color: #0F8CFF;
  color: #fff;
  display: flex;
  align-items: center;
  width: max-content;
  margin-right: 0.625rem;
  margin-bottom: 0.5rem;
}
.filter-clear {
  height: 2rem;
  padding: 0 1.25rem;
  display: flex;
  align-items: center;
  background-color: #DDDDDD;
  border-radius: 0.25rem;
  width: max-content;
  margin-top: 0.75rem;
}
.filter-section-h {
  margin-bottom: 1.25rem;
}
.filter-lv1 {
  margin-top: 0;
}

.article-title {
  font-weight: 500;
  margin-bottom: 1.25rem;
}
.article-list {
  font-size: 0.875rem;
  color: #0F8CFF;
  margin-bottom: 1rem;
  display: block;
}

.bottom-link {
  padding: 2.8rem 0;
}
.bottom-link a {
  color: #000;
  text-decoration: none;
  border-bottom: 1px dotted #000;
  margin-bottom: 0.75rem;
}

.result_product {
  width: 10rem;
  padding: 0 0.1875rem;
  margin-bottom: 0.375rem;
}
.result_product__wrapper {
  display: flex;
  flex-wrap: wrap;
}
.result_product .product_container__item {
  max-width: unset;
}
.result_product .product_container__item .available {
  font-size: 0.75rem;
  align-items: center;
}
.result_product .product_container__item-img {
  height: 6.9375rem;
}
.result_product .product_container__item-info .desc {
  font-size: 0.875rem;
}
.result_catalog {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.375rem;
  align-items: center;
}
.result_catalog .catalog-grid__item {
  flex: 0 0 16%;
  max-width: 16%;
  padding: 0 0.375rem;
  margin-bottom: 0.75rem;
}
.result_catalog .catalog-grid__item .catalogCard {
  background-color: #fff;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.04);
  border-radius: 0.3125rem;
  padding: 0.625rem;
}
.result_catalog .catalog-grid__item .catalogCard-a {
  display: flex;
  flex-direction: column;
}
.result_catalog .catalog-grid__item .catalogCard-image {
  margin-bottom: 0.5rem;
}
.result_catalog .catalog-grid__item .catalogCard-image img, .result_catalog .catalog-grid__item .catalogCard-image picture {
  width: 100%;
  height: 7.5rem;
  object-fit: contain;
}
.result_catalog .catalog-grid__item .catalogCard-title {
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
}
.result .result_sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.375rem 0 1.625rem;
}
.result .result_sort__block {
  display: flex;
  align-items: center;
}
.result .result_sort__block::-webkit-scrollbar-thumb {
  background: transparent !important;
}
.result .result_sort__block::-webkit-scrollbar-track {
  background: transparent !important;
}
.result .result_sort__block-item {
  padding: 0 0.3125rem;
}
.result .result_sort__block-item:first-child {
  padding-left: 0;
}
.result .result_sort__block-item > * {
  white-space: nowrap;
}
.result .result_sort__block-item .white-box {
  padding: 0 1rem;
  height: 2.625rem;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: #000;
  font-weight: 400;
  background-color: #fff;
  border-radius: 0.3125rem;
}
.result .result_sort__block-item .white-box img {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.625rem;
}

.progress {
  border: 1px solid #009217;
  border-radius: 0.625rem;
  padding: 0.875rem 0.75rem;
  background-color: #fff;
}
.progress_title {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  color: #009217;
  padding-bottom: 0.25rem;
  display: flex;
  align-items: center;
}
.progress_title svg {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}
.progress_container {
  position: relative;
}
.progress_path {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 0.25rem;
  width: 100%;
  border-radius: 0.25rem;
  background-color: #EDFFEC;
}
.progress_path__color {
  height: 0.25rem;
  border-radius: 0.5rem;
  background-color: #009217;
}
.progress_markers {
  display: flex;
  justify-content: space-between;
  padding: 0 2% 0 20%;
  align-items: center;
  position: relative;
  z-index: 2;
}
.progress_marker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: #009217;
  height: 2.75rem;
  width: max-content;
}
.progress_marker.first {
  color: #000;
  position: absolute;
  left: 0;
  justify-content: flex-end;
}
.progress_marker__sum, .progress_marker__persent {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
}
.progress_marker__dot {
  width: 0.375rem;
  height: 0.375rem;
  background-color: #009217;
  border-radius: 100%;
}
.progress_marker__dot.active {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #fff;
  border: 1px solid #009217;
}

.basket_modal {
  position: fixed;
  right: -100%;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow: auto;
  transition: 0.3s;
  background-color: #F7F7FC;
}
.basket_modal.basket_show {
  right: 0;
}
.basket_modal__header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: 3.3125rem;
}
.basket_modal__header-title {
  font-size: 1rem;
  line-height: 1.875rem;
}
.basket_modal__header-close {
  position: absolute;
  left: 1.25rem;
}
.basket_modal__header-close svg {
  width: 1.5rem;
  height: 1.5rem;
}
.basket_modal__content {
  padding: 1.5rem;
}
.basket_modal__item {
  width: 100%;
  height: 10rem;
  border-radius: 0.625rem;
  box-shadow: 0 0 3rem rgba(0, 0, 0, 0.04);
  background-color: #fff;
  margin-bottom: 0.625rem;
  padding-right: 1.8rem;
  position: relative;
}
.basket_modal__item svg {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  width: 1rem;
  height: 1rem;
}
.basket_modal__item-img {
  flex: 0 0 5rem;
  max-width: 5rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
}
.basket_modal__item-img img {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.basket_modal__item-text {
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  padding-bottom: 0.8rem;
}
.basket_modal__item-counter {
  display: flex;
  align-items: center;
  padding-right: 2rem;
  width: max-content;
}
.basket_modal__item-price {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.basket_modal__item.is-enlarged {
  height: unset;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.basket_modal__item.is-enlarged .basket_modal__item-img {
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  padding-bottom: 1rem;
  height: 5.625rem;
}
.basket_modal__item.is-enlarged .basket_modal__item-img:last-child {
  padding-bottom: 0;
}
.basket_modal__item.is-enlarged .basket_modal__item-img img {
  max-width: 5rem;
}
.basket_modal__item.is-enlarged .basket_modal__item-price {
  flex-grow: unset;
}
.basket_modal .widget_title {
  padding: 1.5rem 0;
  font-size: 1.125rem;
}
.basket_modal .empty {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.basket_modal .empty > svg {
  width: 11rem;
  height: 13.1875rem;
}
.basket_item.basket_item__sm {
  height: 4.375rem;
  box-shadow: none;
  border: 1px solid #DDDDDD;
  border-radius: 0.5rem;
  padding-right: 1rem;
  margin-bottom: 0.5rem;
}
.basket_item.basket_item__sm .basket_item-text {
  padding: 0;
  flex: 0 0 10rem;
  max-width: 10rem;
  display: flex;
  align-items: center;
}
.basket_item.basket_item__sm .basket_item-img {
  flex: 0 0 2.625rem;
  max-width: 2.625rem;
  margin-right: 1rem;
}
.basket_item svg {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  width: 1rem;
  height: 1rem;
}
.basket_item-img img {
  width: 100%;
  min-height: 100%;
  object-fit: contain;
}
.basket_item-price {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.header {
  border-bottom: 1px solid #EAEAEA;
  padding-bottom: 1rem;
  margin-bottom: 0.25rem;
}
.header .search-input {
  position: absolute;
  width: 100%;
  left: 0;
  border-top: 1px solid #ccc;
  box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.1);
  border-radius: 0 0 0.625rem 0.625rem;
  overflow: hidden;
  z-index: 2;
}
.header .search_result__item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.header .search_result__item:last-child {
  margin-bottom: 0;
}
.header .search_result__item-img {
  flex: 0 0 3.125rem;
  max-width: 3.125rem;
  height: 3.125rem;
  border-radius: 0.3125rem;
  margin-right: 1rem;
}
.header .search_result__item-img img {
  width: 100%;
  min-height: 100%;
  object-fit: contain;
}
.header .search_result__item-text {
  font-weight: 400;
  font-size: 0.875rem;
}
.header_container.light {
  height: 3.3125rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header_container.light svg {
  left: 1.25rem;
  width: 1.5rem;
  height: 1.5rem;
}
.header .burger {
  width: 4.2rem;
  height: 3rem;
  margin-left: -1rem;
}
.header .burger .ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 4.2rem;
  height: 3rem;
}
.header .burger .ham.active {
  width: 4.2rem;
  height: 4.2rem;
}
.header .burger .hamRotate.active {
  transform: rotate(45deg);
}
.header .burger .hamRotate180.active {
  transform: rotate(180deg);
}
.header .burger .ham .line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #000;
  stroke-width: 2;
  stroke-linecap: round;
}
.header .burger .ham4 .top {
  stroke-dasharray: 40 121;
}
.header .burger .ham4 .bottom {
  stroke-dasharray: 40 121;
}
.header .burger .ham4.active .top {
  stroke-dashoffset: -68px;
}
.header .burger .ham4.active .bottom {
  stroke-dashoffset: -68px;
  stroke-dasharray: 40 121;
}
.header .logo {
  width: 8.66rem;
  height: 2.3125rem;
}
.header .find {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header .find svg {
  width: 1.5rem;
  height: 1.5rem;
}
.header .cart {
  margin-left: 1.25rem;
  display: flex;
  align-items: center;
}
.header .cart_count {
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background-color: #FF0000;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0.3rem;
  top: -0.05rem;
}
.header .cart svg {
  width: 1.875rem;
  height: 1.875rem;
  margin-right: 0.75rem;
  fill: #0F8CFF;
}
.header .menu {
  position: fixed;
  z-index: 10;
  width: 17.5rem;
  background-color: #fff;
  top: 0;
  height: 100vh;
  max-height: max-content;
  overflow: auto;
  transition: 0.3s;
  left: -100%;
}
.header .menu.show, .header .menu.visible {
  left: 0;
}
.header .menu_header {
  display: flex;
  align-items: center;
  height: 3.375rem;
  padding: 0 1.25rem;
  background-color: #F7F7FC;
}
.header .menu_header svg {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1.5rem;
}
.header .menu_header .logo {
  width: 8.25rem;
  height: 2.1875rem;
  margin-right: 0;
}
.header .menu_container {
  padding: 1.5rem;
}
.header .menu_container__links {
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
}
.header .menu_container__links--item {
  color: #333333;
  padding: 0.625rem 0;
}
.header .menu_container__links--item:first-child {
  padding-top: 0;
}
.header .menu_container__links--item:last-child {
  padding-bottom: 0;
}
.header .menu_container__links--item a {
  color: #333333;
  padding: 0.625rem 0;
}
.header .menu_container__links--item a:first-child {
  padding-top: 0;
}
.header .menu_container__links--item a:last-child {
  padding-bottom: 0;
}
.header .menu .socials_langs {
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
}
.header .menu .socials_langs__icons {
  margin: 0 -0.5rem;
}
.header .menu .socials_langs__icons a {
  padding: 0 0.5rem;
}
.header .menu .socials_langs__icons a svg {
  width: 1.5rem;
  height: 1.5rem;
}
.header .menu .catalog {
  padding-top: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  flex-direction: column;
}
.header .menu .catalog_item {
  display: flex;
  align-items: center;
  height: 3rem;
  padding: 0 0.75rem;
  background-color: #F7F7FC;
  cursor: pointer;
}
.header .menu .catalog_item:nth-child(2n) {
  background-color: #fff;
}
.header .menu .catalog_item img {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.625rem;
}
.header .menu .catalog_item span {
  font-size: 0.875rem;
  color: #000;
  line-height: 1rem;
  font-weight: 500;
}

.footer {
  padding: 3.125rem 2.5rem 4.5rem;
  background-color: #252525;
}
.footer * {
  font-weight: 400;
  color: #fff !important;
  font-size: 0.875rem;
  line-height: 2rem;
}
.footer-copyright {
  margin-bottom: 1rem;
}
.footer_wrapper {
  display: flex;
  justify-content: space-between;
}
.footer_left {
  flex: 0 0 35%;
  max-width: 35%;
  font-size: 0.75rem;
}
.footer_left img {
  margin-bottom: 2.25rem;
}
.footer_right {
  flex-grow: 1;
  display: flex;
}
.footer_right__item .text-sm {
  margin-bottom: 0.375rem;
}
.footer_right__item .footer-social svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: #fff;
}
.footer_right__item .footer-tels {
  position: relative;
}
.footer_right__item .footer-tels ul {
  margin: 0;
}
.footer_right__item .footer-tels ul li {
  padding: 0.25rem 0;
  position: relative;
}
.footer_right__item .footer-tels svg {
  width: 1rem;
  height: 1rem;
  fill: #fff;
  position: absolute;
  left: -1.5rem;
  top: 50%;
  transform: translateY(-50%);
}
.footer_right__item .footer-email {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.footer_phone {
  padding-top: 0.25rem;
  flex-wrap: wrap;
}
.footer_phone div {
  font-size: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin: 0 0.5rem;
  font-weight: 400;
}
.footer__langs svg {
  width: 1.25rem;
  height: 1.25rem;
  fill: #fff;
  margin-right: 1.25rem;
}
.footer__langs .lang-switcher__body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer .regional-widget-picker {
  display: flex;
  justify-content: center;
}
.footer .regional-widget-picker select {
  background-color: #252525;
}
.footer .regional-widget-picker select option {
  background-color: #000;
}
.footer-legal p {
  line-height: 1.25rem;
}