#newReviewAccount.modal .basket_item-img{
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.account{
  .rewievs_block{
    padding: 1.875rem;
  }
  &_data{
    input{
      height: 2.8125rem;
      border-radius: $border-radius;
      border: 1px solid $gray-400;
      padding-left: 0.875rem;
      font-size: 1rem;
    }
    .title_26{
      margin-bottom: 3.44rem;
    }

    &__wrapper{
      //padding-left: 1.5rem;
    }
    .personal_data{
      input{
        display: none;
      }
      &.enabled{
        input{
          display: block;
        }
        span{
          display: none;
        }
        .edit{
          display: none !important;
        }
        .data_btn__events{
          display: flex !important;
        }
      }
    }
    .delivery_address{
      .address-editing{
        display: none;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        &-wrapper{

          &>div{

            input{
              width: 100%;
            }

          }
        }
      }
      .enabled{
        .address-editing{
          display: flex;
          .address_actions{
            padding-top: 1.25rem;
          }
        }
        .address-saved{
          display: none;
        }
      }
    }
    .address_actions{
      margin: 0 -0.75rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &>div{
        padding: 0 0.75rem;
      }
      .btn_square{
        height: 2.25rem;
        width: 2.25rem;
        background-color: $gray-500;
        padding: 0;
        svg{
          width: 1.25rem;
          height: 1.25rem;
          stroke: $black;
          fill: none;
        }
      }
    }
  }
  &_sidebar{

  }
}