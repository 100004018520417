.basket{
  &_modal{
    position: fixed;
    right: -100%;
    top:0;
    bottom: 0;
    width: 100%;
    overflow: auto;
    transition: .3s;
    background-color: $gray-100;
    &.basket_show{
      right: 0;
    }
    &__wrapper{

    }
    &__header{
      display: flex;
      align-items: center;
      justify-content: center;
      //padding-bottom: 1.875rem;
      background-color: #fff;
      height: 3.3125rem;
      &-title{
        font-size: 1rem;
        line-height: 1.875rem;
      }
      &-close{
        position: absolute;
        left: 1.25rem;
        svg{
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
    &__content{
      padding: 1.5rem;

    }
    &__item{
      width: 100%;
      height: 10rem;
      border-radius: 0.625rem;
      box-shadow: 0 0 3rem rgba(0, 0, 0, 0.04);
      background-color: #fff;
      margin-bottom: 0.625rem;
      padding-right: 1.8rem;
      position: relative;
      svg{
        position: absolute;
        top: 0.75rem;
        right: 0.75rem;
        width: 1rem;
        height: 1rem;
      }
      &-img{
        flex: 0 0 5rem;
        max-width: 5rem;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        img{
          width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }
      &-text{
        font-size: $font-size-sm;
        display: flex;
        align-items: center;
        padding-bottom: 0.8rem;
      }
      &-counter{
        display: flex;
        align-items: center;
        padding-right: 2rem;
        width: max-content;
      }
      &-price{
        display: flex;
        align-items: center;
        flex-grow: 1;
      }
      &.is-enlarged{
        height: unset;
        flex-direction: column;
        padding-top: 1rem;
        padding-bottom: 1rem;
        .basket_modal__item{
          &-img{
            display: flex;
            flex: 0 0 100%;
            max-width: 100%;
            padding-bottom: 1rem;
            height: 5.625rem;
            &:last-child{
              padding-bottom: 0;
            }
            img{
              max-width: 5rem;
            }
          }
        }
        .basket_modal__item-price{
          flex-grow: unset;
        }
      }
    }
    .widget{
      &_title{
        padding: 1.5rem 0;
        font-size: $font-size-lg;
      }
    }
    .empty{
      display: flex;
      flex-direction: column;
      align-items: center;
      &>svg{
        width: 11rem;
        height: 13.1875rem;
      }

    }
  }
  &_item {
    &.basket_item__sm{
      height: 4.375rem;
      box-shadow: none;
      border: 1px solid #DDDDDD;
      border-radius: 0.5rem;
      padding-right: 1rem;
      margin-bottom: 0.5rem;
      .basket_item{
        &-text{
          padding: 0;
          flex: 0 0 10rem;
          max-width: 10rem;
          display: flex;
          align-items: center;
        }
        &-img{
          flex: 0 0 2.625rem;
          max-width: 2.625rem;
          margin-right: 1rem;
        }
      }
    }
    svg {
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;
      width: 1rem;
      height: 1rem;
    }
    &-img{
      img{
        width: 100%;
        min-height: 100%;
        object-fit: contain;
      }
    }
    &-price{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
    }

  }
}
