.products_list{
  margin: 0 -0.4375rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &__item{
    &-wrapper{
      padding: 0 0.4375rem;
      flex: 0 0 50%;
      max-width: 50%;
      margin-bottom: 1.125rem;
    }
    height: 11.125rem;
    background: $white;
    box-shadow: 0 0.25rem 3.125rem rgba(0, 0, 0, 0.04);
    border-radius: $border-radius*2;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.75rem 1rem 1.625rem;
    &-img{
      flex: 0 0 6.125rem;
      max-width: 6.125rem;
      height: 6.125rem;
      img{
        width: 100%;
        min-height: 100%;
      }
    }
    &-text{
      text-align: center;
      font-weight: 500;
      font-size: $font-size-md;
      color: $black;
    }
  }
}