.all_reviews{
  padding-bottom: 2.8125rem;
  .reviews_count{
    padding: 0.25rem 0.5rem;
    border-radius: $border-radius;
    color: $white;
    font-size: $font-size-base;
    font-weight: 600;
    background-color: $orange;
  }
  .gray_block{
    margin-bottom: 1rem;
    padding: 1.25rem;
  }
}
.reviews_form{
  width: 100%;
  padding: 2.8125rem 1.25rem 1.25rem;
  border-radius: $border-radius*2;
  overflow: hidden;
  margin-bottom: 1.5rem;
  &__wrapper{
    flex: 0 0 21.875rem;
    max-width: 21.875rem;
    .form-group{
      padding-top: 0.625rem;
      padding-bottom: 0;
      &:first-child{
        padding: 0;
      }
    }
  }
  .card{
    &_title{
      padding: 1.5rem 1.5rem 0;
    }
    &_body{
      padding: 1.5rem;
    }
  }
}