.footer {
  padding: 3.125rem 2.5rem 4.5rem;
  *{
    font-weight: 400;
    color: #fff !important;
    font-size: $font-size-md;
    line-height: 2rem;
  }
  background-color: #252525;
  &-copyright{
    margin-bottom: 1rem;
  }
  &_wrapper{
    display: flex;
    justify-content: space-between;
  }
  &_left{
    flex:0 0 35%;
    max-width: 35%;
    font-size: 0.75rem;

    img{
      margin-bottom: 2.25rem;
    }
  }
  &_right{
    flex-grow: 1;
    display: flex;
    &__item{
      .text-sm{
        margin-bottom: 0.375rem;
      }
      .footer-social{
        svg{
          width: 1.5rem;
          height: 1.5rem;
          fill: $white;
        }
      }
      .footer-tels{
        position: relative;
        ul{
          margin: 0;
          li{
            padding: 0.25rem 0;
            position: relative;
          }
        }
        svg{
          width: 1rem;
          height: 1rem;
          fill: $white;
          position: absolute;
          left: -1.5rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .footer-email{
        margin-top: 1rem;
        margin-bottom: 2rem;
      }
      .schedule{

      }
    }
  }
  &_phone{
    padding-top: 0.25rem;
    flex-wrap: wrap;
    div{
      font-size: $font-size-base;
      border-bottom: 1px solid rgba(255,255,255, 0.5);
      margin: 0 0.5rem;
      font-weight: 400;
    }
  }
  &__langs{
    svg{
      width: 1.25rem;
      height: 1.25rem;
      fill: #fff;
      margin-right: 1.25rem;
    }
    .lang-switcher{
      &__body{
        display: flex;
        justify-content: center;
        align-items: center;

      }
    }
  }
  .regional-widget-picker{
    display: flex;
    justify-content: center;
    select{
      background-color: $bg_black;
      option{
        background-color: #000;
      }
    }
  }
  &-legal{
    p{
      line-height: 1.25rem;
    }
  }
}