//@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../plugins/slick/css/slick.css";
//@import "../plugins/slick/css/slick-theme.css";

@import "../plugins/toastr/toastr.css";

@import "variables";
@import "typography";
@import "breadcrumbs";
@import "before_footer";

@import "product";
@import "checkout";
@import "search";
@import "thank";
@import "landing";
@import "personal_account/account";
@import "personal_account/personal";
@import "personal_account/my_orders";
@import "personal_account/my_rewievs";
@import "error";
@import "contact";
@import "catalogue/catalog";
@import "catalogue/article_page";
@import "catalogue/store_list";
@import "catalogue/reviews";
@import "products_list";
@import "filter_product";

@import "progress";

@import "basket";

@import "header";
@import "footer";


//@import "landing";
//@import "pages/business";
//@import "pages/news";

