.landing{
  padding-top: 1.25rem;
  .banner.banner_lg{
    padding: 1.375rem 1.25rem;
  }
  .container{
    padding: 0 1.25rem;
  }
  .color_card{
    height: 11rem;
    padding-top: 1rem;
  }
  &_menu{
    background-color: #fff;
    border: 0.3125rem solid #fff;
    box-shadow: 0 0 3.125rem rgba(0, 0, 0, 0.04);
    border-radius: 0.625rem;
    &__item{
      background-color: $gray-500;
      height: 2.625rem;
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      cursor: pointer;
      img{
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.75rem;
      }
      &:nth-child(2n){
        background-color: #fff;
      }
      svg{
        width: 0.4375rem;
      }
      &:hover{
        .landing_submenu{
          opacity: 1;
          pointer-events: unset;
          margin-left: 0;
        }
      }
    }
  }
  &_submenu{
    position: absolute;
    width: 14rem;
    padding: 1rem 1.5rem;
    background-color: #fff;
    border-radius: 0 10px 10px 0;
    top: -0.3125rem;
    left: 100%;
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    margin-left: -30px;
    transition: .3s;
    box-shadow: 25px 25px 30px rgba(0, 0, 0, 0.1);
    &>a{
      display: block;
      font-size: $font-size-md;
      padding: 0.5rem 0;
    }
  }
  .categories{
    padding-top: 1.25rem;
    margin: 0 -0.4375rem;
    display: flex;
    flex-wrap: wrap;
    &_item{
      padding: 0 0.4375rem;
      flex: 0 0 50%;
      max-width: 50%;
      display: flex;
      flex-direction: column;
      .category_card{
        flex-grow: 1;
        background: #fff;
        border-radius: 0.625rem;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0.875rem;
        min-height: 9.5625rem;
        &__img{
          width: 100%;
          height: 4.625rem;
          display: flex;
          justify-content: center;
          img{
            width: 100%;
            min-height: 100%;
            object-fit: contain;
          }
        }
        &__title{
          font-weight: 500;
          font-size: $font-size-md;
          padding-top: 0.5rem;
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
        &__links{
          flex-grow: 1;
          a{
            display: block;;
            padding: 0.625rem;
            font-size: $font-size-md;
            background-color: #F7F7FD;
            border-radius: $border-radius;
            &:nth-child(2n){
              background-color: #fff;
            }
          }
        }
        &__footer{
          padding: 0.625rem;
          font-size: $font-size-md;
          font-weight: 600;
          text-align: center;
        }
      }
    }
  }
  .last_order{
    padding-top: 2.1875rem;
    &__wrapper{
      display: flex;
      margin: 0 -0.8125rem;
      flex-wrap: wrap;
    }
    &__left{
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: 0 0.8125rem;
    }
    &__right{
      flex: 0 0 19.125rem;
      max-width: 19.125rem;
      padding: 0 0.8125rem;
    }
  }
  .cart{
    padding-top: 2.1875rem;
    margin-top: 2.5rem;
    padding-bottom: 2.5rem;
    background-color: #fff;
    .product_container{
      &__item{
        width:10.55rem;
        &-info{
          padding-left: 0.68rem;
          padding-right: 0.68rem;
          padding-bottom: 1.125rem;
        }
      }
    }
  }
  .landing_cart__slide-wrap{
    padding: 0 3.75rem;
  }
  .promo{
    padding: 2.8rem 0;
    .color_card{
      padding: 1.375rem 1rem 1.375rem 1.875rem;
    }
    &_title{
      font-size: 2.25rem;
      line-height: 2.625rem;
    }
    &_text{
      width: 13.125rem;
      font-size: $font-size-md;
      margin-bottom: 1.3rem;
    }
    svg{
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .action_slider__wrapper{
    padding: 0 2.8rem;
    .product_container__item{
      //width: 18.125rem;
      max-width: unset;
    }
  }
  .articles{
    padding: 2.5rem 0;
    &_slider{
      margin: 0 -0.625rem;
      display: flex;
      &__item{
        width: 14.125rem;
        padding: 0 0.625rem;
        line-height: 1.25rem;
        font-weight: 500;
        margin-bottom: 1.25rem;
      }
    }
  }
  .faq{
    padding: 2.5rem 0;
    background-color: #fff;
    .accordion{
      //height: 4.25rem;
      &_item{
        padding: 1.4rem 1.25rem;
      }
    }
  }
  .accordion_item__title{
    font-size: $font-size-base;
  }
  &_page{
    .container{
      padding: 0 1rem;
    }
    section{
      padding: 3.125rem 0;
    }
    h2{
      margin-bottom:1.6875rem;
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.5rem;
    }
    .banner{
      color: $white;
      background-image: url(../img/landing_page/banner.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: .875rem;
      box-shadow: 0 0 8rem rgba(0, 0, 0, 0.04);
      padding: 16.75rem 1.125rem 2.5rem;
      height: 33.375rem;
      margin-bottom: 6.875rem;
      &_wrapper{
        max-width: 47rem;
      }
      &_bread-crumbs{
        margin-bottom: 1.125rem;
        font-weight: 500;
        font-size: 1rem;
        text-align: center;
        line-height: 1.5625rem;
      }
      &_title{
        font-size: 1.875rem;
        line-height: 2rem;
        margin-bottom: 1.125rem;
        font-weight: 500;
        text-align: center;
      }
      &_price{
        border-radius: 6.25rem;
        border: 1px solid $white;
        margin-bottom: 1.125rem;
        display: flex;
        width: max-content;
        &>*{
          font-size: 0.75rem;
          font-weight: 500;
          line-height: 1rem;
        }
        &-left{
          padding: 0.625rem 0.75rem;
          border-right: 1px solid $white;
        }
        &-right{
          padding: 0.625rem 0.75rem;
        }
      }
      &_btn{
        height: 3.125rem;
        width: 100%;
        font-weight: 500;
        font-size: 1.125rem;
      }
    }
    .advantages{
      &_list{
        &-item{
          margin-bottom: 1.25rem;
          display: flex;
          align-items: flex-start;
          svg{
            flex: 0 0 1.5rem;
            max-width: 1.5rem;
            height: 1.5rem;
            margin-right: 0.625rem;
            fill: #0F8CFF;
          }
          .text{
            font-weight: 400;
            font-size: 1rem;
          }
        }
      }
    }
    .personal{
      &_items{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 -0.4375rem 0.4375rem;
        flex-wrap: wrap;
        &-item{
          padding: 0 0.4375rem;
          margin-bottom: 0.75rem;
          .item_wrapper{
            background-color: $white;
            border-radius: 0.875rem;
            padding: 1rem 1.5rem 0.85rem;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 9.82rem;
            height: 9.82rem;
            img{
              width: 5.875rem;
              height: 5.875rem;
              margin-bottom: 0.75rem;
            }
          }
          .text{
            font-weight: 500;
            font-size: 0.75rem;
            line-height: 1rem;
            text-align: center;
          }
        }
      }
    }
    .structure{
      &_container{
        position: relative;
        text-align: center;
        img{
          width: 17.5rem;
          margin: 0 auto;
          //height: 36.25rem;margin: 0 auto;
        }
      }
      &_white-block{
        background-color: #fff;
        padding: 0.9375rem 0.75rem 0.9375rem 0.9375rem;
        border-radius: 0.625rem;
        box-shadow: 0 0.25rem 3.125rem rgba(0, 0, 0, 0.04);
        height: max-content;
        //position: absolute;
        text-align: left;
        font-size: 0.875rem;
        .title{
          font-size: 1rem;
          font-weight: 500;
          margin-bottom: 0.625rem;
        }
        &.top{
          margin-left: auto;
          width: 15.4375rem;
        }
      }
    }
    .cost{
      background-color: $white;
      .col-4, .col-8{
        padding: 0 0.25rem;
      }
      &_wrapper{
        .active{
          color: $white;
          background-color: $blue;
        }
      }
      &_color__block{
        background-color: #F9F9FF;
        border-radius: 0.625rem;
        overflow: hidden;
        &-item{
          padding: 1.875rem 1.125rem;
          font-size: 1.25rem;
          font-weight: 700;
          width: max-content;
          &.before{
            position: relative;
            &::before{
              content: '';
              position: absolute;
              bottom: 0;
              width: 5.3125rem;
              height: 1px;
              background-color: #fff;
              left: 50%;
              transform: translateX(-50%);
            }
            &.gray{&::before{background-color: #C6C6C6;}}
          }
        }
      }
    }
    .etaps{
      &_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
      }
      &_container{
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 1rem;
      }
      &_item{
        background-color: $white;
        padding: 4.25rem 1.8rem 1.6875rem 1.5rem;
        border-radius: $border-radius*2;
        position: relative;
        &::before{
          counter-increment: section;
          content: counter(section);
          position: absolute;
          width: 2.25rem;
          height: 2.25rem;
          border-radius: 100%;
          background-color: #F9F9FF;
          color: #0F8CFF;
          font-size: 0.875rem;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 1.5rem;
          left: 1.5rem;
        }
        &__title{
          font-weight: 600;
          font-size: 1.125rem;
          margin-bottom: 1.25rem;
        }
      }
    }
    .reviews{
      background-color: #fff;
      &_slider-landing{
        display: flex;
        width: 100%;
        overflow: auto;
        .gray_block{
          width: 16.875rem;
        }
      }
    }
  }

}