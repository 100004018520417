.error{
  .product_container__item{
    min-height: 21.5rem;
    max-width: unset;
    min-width: 15rem;
    margin-bottom: 1rem;
  }
  &-wrapper{
    margin-bottom: 4.375rem;
  }
  &_img{
    width: 17.18rem;
    height: 11.4375rem;
    margin-bottom: 2rem;
  }
}