.checkout{
  padding: 1.25rem 1rem;
  background-color: #F9F9FF;
  .basket_item{
    box-shadow: none;
    border: 1px solid #DDDDDD;
  }
  .change-order-btn{
    top: 50%;
    right: 1.5rem;
    display: none;
  }
  .card{
    border-radius: $border-radius*2;
    padding: 1rem;
    &_title{
      padding-bottom: 1rem;
    }
    &.error{
      border: 1px solid $red !important;
      .wrapper_field{
        span{
          color: $red;
        }
      }
    }
  }
  .wrapper_field{
    display: none;
    margin: 0 -0.625rem;
    span{
      padding: 0 0.625rem;
      position: relative;
    }
  }
  .basket_item__wrapper{
    flex-direction: column;
  }
  .basket_modal__item{
    height: 7.25rem;
    border: 1px solid #DDDDDD;
    border-radius: $border-radius*1.5;
    &-img{
      flex: 0 0 4.75rem;
      max-width: 4.75rem;
    }
  }
  .not-active{
    box-shadow: none;
    .basket_item__wrapper{
      margin: 0 -0.375rem;
    }
    .basket_modal__item{
      flex: 0 0 4.875rem;
      max-width: 4.875rem;
      height: 5.3125rem;
      border: none;
      padding-right: 0;
      margin: 0 0.375rem 0.625rem;
      &-content{
        display: none !important;
      }
      &-img{
        flex: 0 0 4.75rem;
        max-width: 4.75rem;
      }
      svg{
        display: none;
      }
    }
    .btn-primary{
      display: none;
    }
    .btn-primary-outline{
      display: flex !important;
    }
    .get_in, .wrapper_data{
      display: none;
    }
    .wrapper_field{
      display: flex;
      flex-direction: column;
    }
    &.card{
      background-color: #F0F0FE !important;
      box-shadow: 0 0 3.125rem rgba(0, 0, 0, 0.04);
      .card{
        &_body{
          display: flex;
          padding: 1rem 0 0 2.9rem;
          flex-direction: column;
        }
        &_title {
          padding: 0;
          span{
            background-color: $white;
          }
        }
      }
    }
    .widget{
      height: 0;
      opacity: 0;
      overflow: hidden;
      pointer-events: none;
    }
    .basket_item{
      width: unset;
      height: unset;
      padding-right: 0;
      margin-bottom: 0.75rem;
      margin-right: 0.75rem;
      border: none;
      border-radius: $border-radius*1.5;
      overflow: hidden;
      &__wrapper{
        flex-direction: row;
      }
      &-img{
        flex: 0 0 4.875rem;
        max-width: 4.875rem;
        height: 5.3125rem;
      }
      img{
        object-fit: cover;
        width: 100%;
        min-height: 100%;
      }
      &-text,&-counter,&-price,svg{
        display: none;
      }
    }
  }
  .address_fields{
    margin: 0 -0.375rem;
    flex-wrap: wrap;
    &>div{
      padding: 0 0.375rem;
      flex: 0 0 33.33%;
      max-width: 33.33%;
      &:first-child{
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 0.5rem;
      }
      input{
        padding: 0.5rem;
      }
    }
  }
  .description_pay-type{
    width: 100%;
    padding: 1rem 1.25rem;
    border-radius: 0.625rem;
    background-color: $gray-100;
  }
}